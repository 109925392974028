import { useContext, useEffect, useState } from "react";
import "./variant.css";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoaderButton from "../../../Components/LoaderButton";
import { faArrowLeft, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Navigation from "../../../config/Navigation.json";
import Toast from "../../../Components/Toast";
import InputContext from "../../../Components/InputContext";
import APIEndpoint from "../../../config/APIEndpoints.json";
const API_HOST = process.env.REACT_APP_HOST_API_URL;

const VariantValue = () => {
  const { variantId } = useParams();
  const location = useLocation();
  const { showToast } = Toast();
  const navigate = useNavigate();
  const contextObj = useContext(InputContext);
  const [addVariantValues, setAddVariantValue] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [variantValue, setVarientValue] = useState("");
  const [originalValue, setOriginalValue] = useState(""); // Track original value
  const [varientValueError, setVarientValueError] = useState("");
  const [valueDataList, setValueDataList] = useState([]);
  const [variantName, setVariantName] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [modalMode, setModalMode] = useState("add");
  const [editValueId, setEditValueId] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteValueId, setDeleteValueId] = useState(null);
  const [deleteName, setDeleteName] = useState("");
  // Get All Varients Values
  const GetVarientsValues = async () => {
    try {
      let token = getDataFromCookies("7b9KpQ3rW6");
      if (!token) {
        contextObj.setInput("prevPath", location.pathname);
        navigate(`${Navigation.login}`);
        return;
      }
      const response = await fetch(
        `${API_HOST}/${APIEndpoint.getListOfVariantValue}/${variantId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const res = await response.json();
      if (res.status === 401 && res.message === "You are not authorized.") {
        contextObj.setInput("prevPath", location.pathname);
        removeDataFromCookie("7b9KpQ3rW6", "/");
        removeDataFromCookie("loggedIn", "/");
        contextObj.setInput("navigation", "true");
        contextObj.setInput("navigationFrom", "sessionExpired");
        navigate(Navigation.login);
        showToast("info", "Sessions expired!");
      } else if (res.status === 401 && res.message !== "") {
        showToast("error", res.message);
      } else if (res.status === 404) {
        setVariantName(res.data.variant_name);
        setValueDataList([]);
      } else if (res.status === 200) {
        setVariantName(res.data.variant_name);
        setValueDataList(res.data.variant_values);
      }
      setPageLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    GetVarientsValues();
  }, []);

  // Add Modal Popup open & close
  const AddVariantValuesPopup = () => {
    setModalMode("add");
    setAddVariantValue(true);
    setVarientValue(""); // Clear previous value
    setVarientValueError("");
  };

  const closeAddValuePopup = () => {
    setAddVariantValue(false);
    setVarientValueError("");
    setVarientValue("");
  };

  const EditVariantValuesPopup = (item) => {
    setModalMode("edit");
    setEditValueId(item.id); // Set ID of the value being edited
    setOriginalValue(item.value); // Store the original value
    setVarientValue(item.value); // Populate the input with the current value
    setAddVariantValue(true);
    setVarientValueError("");
  };

  // Value set for Add Varient values
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "value") {
      setVarientValue(value.trimStart());
      setVarientValueError("");
    }
  };

  // API Call to Add/Edit Varient Value
  const AddEditValueData = async () => {
    setLoadingButton(true);
    let error = false;

    // Validation
    if (variantValue === "" || variantValue.toLowerCase() === "null") {
      setVarientValueError("This field is required and cannot be Empty.");
      error = true;
    } else if (variantValue.length < 2 || variantValue.length > 30) {
      setVarientValueError("The value must be between 2 and 30 characters.");
      error = true;
    }

    if (error) {
      setLoadingButton(false);
      return;
    }

    const Payload = {
      value: variantValue,
      variant_id: variantId,
    };

    let token = getDataFromCookies("7b9KpQ3rW6");

    try {
      let response;
      if (modalMode === "add") {
        response = await fetch(`${API_HOST}/${APIEndpoint.crudVarientValue}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(Payload),
        });
      } else {
        // Editing: Use PUT request
        response = await fetch(
          `${API_HOST}/${APIEndpoint.crudVarientValue}/${editValueId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(Payload),
          }
        );
      }

      const data = await response.json();
      if (data.status === 201 || data.status === 200) {
        showToast("success", data.message);
        closeAddValuePopup();
        GetVarientsValues();
      } else if (data.status === 400) {
        if (data.message) {
          setVarientValueError(data.message);
        } else if (data.errors.value) {
          setVarientValueError(data.errors.value[0]);
        }
      } else if (
        data.status === 401 &&
        data.message === "You are not authorized."
      ) {
        contextObj.setInput("prevPath", location.pathname);
        removeDataFromCookie("7b9KpQ3rW6", "/");
        removeDataFromCookie("loggedIn", "/");
        contextObj.setInput("navigation", "true");
        contextObj.setInput("navigationFrom", "sessionExpired");
        navigate(Navigation.login);
        showToast("info", "Sessions expired!");
      } else if (data.status === 401 && data.message !== "") {
        showToast("error", data.message);
      } else {
        console.error("Unexpected response:", data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingButton(false);
    }
  };

  // Check if the update button should be disabled
  const isUpdateDisabled =
    variantValue === originalValue ||
    variantValue === "" ||
    variantValue.toLowerCase() === "null" ||
    variantValue.length < 2 ||
    variantValue.length > 30;

  const buttonStyle = {
    opacity: isUpdateDisabled ? 0.5 : 1,
    cursor: isUpdateDisabled ? "not-allowed" : "pointer",
  };
  // Delete Modal popup
  const openDeleteModal = (item) => {
    setDeleteValueId(item.id);
    setDeleteName(item.value);
    setDeleteModalVisible(true);
  };

  const closeDeleteModal = () => {
    setDeleteValueId(null);
    setDeleteModalVisible(false);
  };

  // Delete Api Call
  const deleteValueData = async () => {
    if (deleteValueId === null) return;

    setLoadingButton(true);

    try {
      let token = getDataFromCookies("7b9KpQ3rW6");
      const response = await fetch(
        `${API_HOST}/${APIEndpoint.crudVarientValue}/${deleteValueId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();

      if (data.status === 200) {
        showToast("success", "Value deleted successfully");
        GetVarientsValues(); // Refresh the list after deletion
      } else if (data.status === 401) {
        if (data.message === "You are not authorized.") {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate(Navigation.login);
          showToast("info", "Session expired!");
        } else {
          showToast("error", data.message);
        }
      } else {
        showToast("error", "Failed to delete value");
      }
    } catch (error) {
      console.error(error);
      showToast("error", "An error occurred");
    } finally {
      setLoadingButton(false);
      closeDeleteModal();
    }
  };

  return (
    <div className="page-data">
      <div className="page-heading">
        <div className="breadcrumb">
          <FontAwesomeIcon
            onClick={() => {
              window.history.back();
            }}
            icon={faArrowLeft}
            style={{
              fontSize: "16px",
              color: "gray",
              cursor: "pointer",
              marginRight: "10px",
            }}
          />
          <li className="active">Variants Values </li>
        </div>
      </div>
      {pageLoading ? (
        <div className="loading-container">
          <LoaderButton />
        </div>
      ) : (
        <div>
          <div className="variant-heading-container">
            <h1 className="varient-name-heading">
              {" "}
              Name of Variant: {variantName}
            </h1>
            <button
              className="primary-btn"
              onClick={() => {
                AddVariantValuesPopup();
              }}
            >
              Add Value
            </button>
          </div>
          <hr />
          <div className="variantValueContainer">
            <div className="values-box">
              {valueDataList.length === 0 ? (
                <p
                  style={{
                    fontSize: "16px",
                    textAlign: "center",
                    padding: "20px",
                  }}
                >
                  Your list is currently empty. To add new value, click the "Add
                  Value" button above.
                </p>
              ) : (
                valueDataList.map((item) => (
                  <div key={item.id} className="value-list-box">
                    <p
                      style={{
                        fontSize: "16px",
                        textAlign: "center",
                        padding: "20px",
                        backgroundColor: item.value,
                        borderRadius: "10px",
                        marginBottom: "15px",
                      }}
                    >
                      {item.value}
                    </p>
                    <div className="button-container-box">
                      <button
                        className="action-icon edit-button"
                        title="Edit"
                        onClick={() => EditVariantValuesPopup(item)}
                        style={{
                          opacity: 1,
                          cursor: "pointer",
                        }}
                      >
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </button>
                      <button
                        type="button"
                        className="action-icon trash-button"
                        title="Delete"
                        onClick={() => openDeleteModal(item)}
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </button>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
          {addVariantValues && (
            <div>
              <div
                className="modailmaindiv"
                style={{ border: "1px solid green" }}
              >
                <div className="AdminEditBrandBox">
                  <h3 className="category-modal-heading">
                    {modalMode === "add" ? "Add Value" : "Edit Value"}
                  </h3>

                  <span
                    className="modal-close"
                    onClick={() => {
                      closeAddValuePopup();
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </span>

                  <label htmlFor="">Name</label>
                  <input
                    type="text"
                    name="value"
                    maxLength={30}
                    value={variantValue}
                    className="Add_category_input"
                    onChange={handleChange}
                    placeholder="Enter name"
                  />
                  {<div className="red" style={{ maxWidth:"100%" }}>{varientValueError}</div>}
                  <div className="modal-buttons">
                    <button
                      type="button"
                      className="btn secondary-btn"
                      onClick={() => {
                        closeAddValuePopup();
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn primary-btn"
                      onClick={() => {
                        AddEditValueData();
                      }}
                      disabled={isUpdateDisabled}
                      style={buttonStyle}
                    >
                      {loadingButton ? (
                        <LoaderButton />
                      ) : modalMode === "add" ? (
                        "Add"
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {deleteModalVisible && (
        <div className="modailmaindiv">
          <div className="AdminEditBrandBox">
            <span
              className="modal-close"
              onClick={() => {
                closeDeleteModal();
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </span>

            <h3 className="modal-heading">Confirm Delete</h3>
            <p>
              Are you sure you want to delete{" "}
              <span style={{ fontWeight: "bold" }}>{deleteName} </span> value?
            </p>
            <div className="modal-buttons">
              <button
                type="button"
                className="btn secondary-btn"
                onClick={closeDeleteModal}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn primary-btn"
                onClick={deleteValueData}
              >
                {loadingButton ? <LoaderButton /> : "Delete"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VariantValue;
