import React, { useEffect, useState, useRef, useContext } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import APIEndpoint from "../../../config/APIEndpoints.json";
import Navigation from "../../../config/Navigation.json";
import Alert from "../../../config/ProjectConstants.json";
import LoaderButton from "../../../Components/LoaderButton";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";
import Toast from "../../../Components/Toast";
import "./inhouse-product.css";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
// import constant
import IMG_HOST from "../../../Components/ReUsed";
import InputContext from "../../../Components/InputContext";
const API_HOST = process.env.REACT_APP_HOST_API_URL;
const BUYER_APP = process.env.REACT_APP_BUYER_DOMAIN;

const InHouseProduct = ({ page }) => {
  const preventEvent = (e) => {
    if (
      !/^[0-9]$/.test(e.key) &&
      e.key !== "Backspace" &&
      e.key !== "Tab" &&
      e.key !== "ArrowLeft" &&
      e.key !== "ArrowRight" &&
      e.key !== "Delete"
    ) {
      e.preventDefault();
    }
  };
  const location = useLocation();
  const navigate = useNavigate();
  const { showToast } = Toast();
  const { slug } = useParams();
  const [loadingButton, setLoadingButton] = useState(false);
  const contextObj = useContext(InputContext);

  const [openSeoEdit, setOpenSeoEdit] = useState(false);

  const metaTitleMaxLength = 70;
  const metaDescMaxLength = 320;
  const [metaTitleRemaining, setMetaTitleRemaining] =
    useState(metaTitleMaxLength);
  const [metaDescRemaining, setMetaDescRemaining] = useState(metaDescMaxLength);

  // set page name
  const [pageName, setPageName] = useState("");
  const [productId, setProductId] = useState("");
  useEffect(() => {
    if (page === "new") {
      setPageName("New");
    } else if (page === "edit") {
      setPageName("Edit");
      const urls = slug.split("-");
      const productId = urls[urls.length - 2];
      setProductId(productId);
    }
  }, [page]);

  // save new products
  const [product, setProduct] = useState({
    name: "",
    status: "",
    description: "",
    category_id: "",
    brand_id: "",
    condition: "",
    age_group_id: "",
    platform_charge_type: "Percentage",
    platform_charge: "",
    gst: "",
    length: "",
    width: "",
    height: "",
    weight: "",
    warehouse_id: "",
    is_donatable: 0,
    is_negotiable: 0,
    status: "Publish",
    hsn: "",
    meta_title: null,
    meta_description: null,
  });

  const [errors, setErrors] = useState({
    has_error: false,
    name: "",
    description: "",
    hsn: "",
    weight: "",
    length: "",
    width: "",
    height: "",
    is_negotiable: "",
    is_donatable: "",
    status: "",
    condition: "",
    age_group_id: "",
    brand_id: "",
    category_id: "",
    estimated_shipping_cost: "",
    meta_title: "",
    meta_description: "",
  });

  // generate slug URL
  const getSlugURL = (productName) => {
    if (productName) {
      let slug = productName.replace(/[^a-zA-Z0-9]+/g, "-").toLowerCase();
      return slug.replace(/-+/g, "-").replace(/^-|-$/g, "");
    }
  };

  // Remove html tags
  const removeHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  // Enable Add product button
  const [enableAddProductBtn, setEnableAddProductBtn] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [disableCalculate, setDisableCalculate] = useState(true);

  // variant

  class VariantProduct {
    constructor(
      is_default = 0,
      new_stock = false,
      update_stock = false,
      variantListVisible = false,
      variantValuesListVisible = false,
      stock_id = null,
      selectedVariant = null,
      variant_values = null,
      product_price = null,
      current_market_price = null,
      thumb_image = null,
      front_image = null,
      left_image = null,
      right_image = null,
      back_image = null,
      top_image = null,
      video_url = null,
      sku = null,
      selling_Price = null,
      // hsn = null,
      barcode = null,
      quantity = null,
      product_price_as_first = false,
      current_market_price_as_first = false,
      variantListShow = false,
      variantValuesListShow = false,
      selectedVariant_error = null,
      variant_values_error = null,
      product_price_error = null,
      current_market_price_error = null,
      thumb_image_error = null,
      front_image_error = null,
      left_image_error = null,
      right_image_error = null,
      back_image_error = null,
      top_image_error = null,
      video_url_error = null,
      sku_error = null,
      hsn_error = null,
      barcode_error = null,
      quantity_error = null,
      selling_Price_error = null
    ) {
      this.is_default = is_default;
      this.new_stock = new_stock;
      this.update_stock = update_stock;
      this.variantListVisible = variantListVisible;
      this.selectedVariant = selectedVariant;
      this.stock_id = stock_id;
      this.variantValuesListVisible = variantValuesListVisible;
      this.variant_values = variant_values;
      this.product_price = product_price;
      this.current_market_price = current_market_price;
      this.thumb_image = thumb_image;
      this.video_url = video_url;
      this.sku = sku;
      this.selling_Price = selling_Price;
      // this.hsn = hsn;
      this.barcode = barcode;
      this.quantity = quantity;
      this.front_image = front_image;
      this.left_image = left_image;
      this.right_image = right_image;
      this.back_image = back_image;
      this.top_image = top_image;
      this.product_price_as_first = product_price_as_first;
      this.current_market_price_as_first = current_market_price_as_first;
      this.variantListShow = variantListShow;
      this.variantValuesListShow = variantValuesListShow;
      // errors =======>
      this.selectedVariant_error = selectedVariant_error;
      this.variant_values_error = variant_values_error;
      this.product_price_error = product_price_error;
      this.current_market_price_error = current_market_price_error;
      this.thumb_image_error = thumb_image_error;
      this.front_image_error = front_image_error;
      this.left_image_error = left_image_error;
      this.right_image_error = right_image_error;
      this.back_image_error = back_image_error;
      this.top_image_error = top_image_error;
      this.video_url_error = video_url_error;
      this.sku_error = sku_error;
      this.hsn_error = hsn_error;
      this.barcode_error = barcode_error;
      this.quantity_error = quantity_error;
      this.selling_Price_error = selling_Price_error;
    }
  }
  const variantsURL = `${API_HOST}/api/admin/product/variants`;
  let [variantsList, setVariantsList] = useState([]);
  const [productUpdate, setProductUpdate] = useState(0);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState({
    modal: false,
    variantIndex: null,
    stock_id: null,
    loading: false,
  });
  let [variantsProductList, setVariantsProductList] = useState([]);

  const checkVariantExist = (variant_index, variant_value) => {
    variantsProductList &&
      variantsProductList.length > 1 &&
      variantsProductList.forEach(async (variant, index) => {
        if (
          variant_index !== index &&
          variant_value.variant_id === variant.variant_values.variant_id &&
          variant_value.id === variant.variant_values.id
        ) {
          await updateVariantField(
            variant_index,
            "variant_values_error",
            "This variant already exists."
          );
        }
      });
  };

  const checkFirstVariantPrice = async (index, field) => {
    let fieldToCheck = "";
    let firstVarientValue = null;
    let errorFieldName = null;
    if (field === "current_market_price_as_first") {
      fieldToCheck = "current_market_price";
      errorFieldName = "current_market_price_error";
    }
    if (field === "product_price_as_first") {
      fieldToCheck = "product_price";
      errorFieldName = "product_price_error";
    }

    if (
      variantsProductList &&
      variantsProductList.length > 0 &&
      variantsProductList[0]
    ) {
      firstVarientValue = variantsProductList[0][fieldToCheck];
    }

    // let
    if (firstVarientValue !== null && firstVarientValue !== "") {
      let updateReadOnly = variantsProductList[0][field];
      await updateVariantField(index, fieldToCheck, firstVarientValue);
      await updateVariantField(index, field, !updateReadOnly);
      await updateVariantField(index, errorFieldName, "");
    } else {
      showToast(
        "error",
        `Update ${fieldToCheck.split("_").join(" ")} in the first variant.`
      );
    }
  };
  const addNewVariant = () => {
    return new Promise((resolve) => {
      let newVariant = new VariantProduct();
      setVariantsProductList((prevVariants) => {
        if (pageName == "New") {
          if (prevVariants.length === 0) {
            newVariant.is_default = 1;
          } else {
            newVariant.is_default = 0;
          }
        }
        const updatedVariants = [...prevVariants, newVariant];
        resolve(updatedVariants);
        return updatedVariants;
      });
    });
  };

  const getSelectedVarient = (index, variant_id, variant_value_id) => {
    variantsList.forEach(async (variant) => {
      if (variant.id == variant_id) {
        await updateVariantField(index, "selectedVariant", variant);
        variant.variant_values.map(async (value) => {
          if (value.id == variant_value_id) {
            await updateVariantField(index, "variant_values", value);
          }
        });
      }
    });
  };
  const updateVariantField = (index, field, value) => {
    return new Promise((resolve, reject) => {
      try {
        setVariantsProductList((prevVariants) => {
          const updatedVariants = [...prevVariants];
          if (updatedVariants[index]) {
            updatedVariants[index][field] = value;
            resolve(updatedVariants); // Resolves the updated variants list
          } else {
            reject(new Error("Index not found")); // Rejects if the index is not found
          }
          return updatedVariants;
        });
      } catch (error) {
        reject(error); // Rejects if there’s an error
      }
    });
  };

  const filterVariant = async (index) => {
    setShowRemoveConfirmation((prev) => ({
      loading: true,
    }));

    if (showRemoveConfirmation.stock_id) {
      deleteStock(showRemoveConfirmation.stock_id);
    } else {
      setVariantsProductList((prevVariants) =>
        prevVariants.filter((_, i) => i !== showRemoveConfirmation.variantIndex)
      );
      cancelRemoveVariant();
    }
  };

  const markDefaultProduct = (selectedIndex) => {
    variantsProductList &&
      variantsProductList.map(async (variantsProduct, index) => {
        await updateVariantField(
          index,
          "is_default",
          selectedIndex == index ? 1 : 0
        );
      });
  };

  const changeVariantDropDownStatus = (targetIndex, field, currentValue) => {
    variantsProductList.map(async (variant, index) => {
      if (targetIndex == index) {
        if (field == "variantListShow") {
          await updateVariantField(index, "variantListShow", !currentValue);
          await updateVariantField(index, "variantValuesListShow", false);
        } else {
          await updateVariantField(
            index,
            "variantValuesListShow",
            !currentValue
          );
          await updateVariantField(index, "variantListShow", false);
        }
      } else {
        await updateVariantField(index, "variantListShow", false);
        await updateVariantField(index, "variantValuesListShow", false);
      }
      if (page == "edit") {
        await updateVariantField(
          index,
          variant.stock_id == null ? "new_stock" : "update_stock",
          true
        );
      }
    });
  };
  const deleteStock = async (stock_id) => {
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      navigate(`${Navigation.login}`);
      return;
    }
    const response = await fetch(
      `${API_HOST}/${APIEndpoint.deleteStockApi}/${stock_id}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const res = await response.json();
    if (res.status === 200) {
      showToast("success", res.message);
      setVariantsProductList((prevVariants) =>
        prevVariants.filter((_, i) => i !== showRemoveConfirmation.variantIndex)
      );
      cancelRemoveVariant();
    } else if (res.message) {
      showToast("error", res.message);
    }

    setShowRemoveConfirmation((prev) => ({
      loading: false,
    }));
  };
  const cancelRemoveVariant = () => {
    setShowRemoveConfirmation((prev) => ({
      ...prev,
      modal: false,
      variantIndex: null,
      stock_id: null,
      loading: false,
    }));
  };

  const fetchVariantsData = (url) => {
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      navigate(`${Navigation.login}`);
      return;
    }

    fetch(`${url}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 200) {
          let data = res.data;
          variantsList = data;
          setVariantsList(data);
        } else {
          console.error(res);
        }
      })
      .then(async () => {
        if (page === "edit") {
          const urls = slug.split("-");

          const productId = urls[urls.length - 2];
          setProductId(productId);
          await getProductDetails(productId);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  // handle selling button
  useEffect(() => {
    if (
      product.product_price !== "" &&
      product.current_market_price !== "" &&
      product.gst !== "" &&
      product.platform_charge_type !== "" &&
      product.platform_charge !== ""
    ) {
      setDisableCalculate(false);
    } else {
      setDisableCalculate(true);
    }
  }, [product]);

  const calculateSellingPrice = async (
     index,
    current_market_price,
    product_price
  ) => {
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }
    let errorHas = false;
    // product price error
    const productPrice = /^[0-9]+$/;
    const isPrice = productPrice.test(product_price);
    if (
      product.status === "Publish" &&
      (product_price === "" || product_price === null)
    ) {
      setErrors((prev) => ({
        ...prev,
        product_price: Alert.required,
      }));

      updateVariantField(index, "selling_price_error", Alert.required);
      errorHas = true;
    } else if (product_price != "" && product_price != null && !isPrice) {
      updateVariantField(index, "selling_price_error", Alert.invalidFormat);
      errorHas = true;
    } else if (
      product_price !== "" &&
      product_price != null &&
      !isPrice
    ) {
      setErrors((prev) => ({
        ...prev,
        product_price: Alert.invalidFormat,
      }));
      errorHas = true;
    } else if (
      product_price !== "" &&
      product_price != null &&
      (product_price > 100000 || product_price < 100)
    ) {
      setErrors((prev) => ({
        ...prev,
        product_price: Alert.valBetween100To100000,
      }));
      errorHas = true;
    } else {
      updateVariantField(index, "selling_price_error", "");
    }

    // current_market_price validation
    const marketPrice = /^[0-9]+$/;
    const isOriginalPrice = marketPrice.test(current_market_price);
    if (
      product.status === "Publish" &&
      (current_market_price === "" ||
        current_market_price === null)
    ) {
      setErrors((prev) => ({
        ...prev,
        current_market_price: Alert.required,
      }));
      errorHas = true;
    } else if (
      current_market_price !== "" &&
     current_market_price != null &&
      !isOriginalPrice
    ) {
      setErrors((prev) => ({
        ...prev,
        current_market_price: Alert.invalidFormat,
      }));
      errorHas = true;
    } else if (
      current_market_price !== "" &&
      current_market_price != null &&
      (current_market_price > 200000 ||
        current_market_price < 200)
    ) {
      setErrors((prev) => ({
        ...prev,
        current_market_price: Alert.valBetween200To200000,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        current_market_price: "",
      }));
    }

    // GST Percentage validation
    if (
      product.status === "Publish" &&
      (product.gst === "" || product.gst === null)
    ) {
      setErrors((prev) => ({
        ...prev,
        gst: Alert.required,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        gst: "",
      }));
    }

    // platform charges validation
    const productPlatformCharge = /^[0-9]\d*$/;
    const isCharge = productPlatformCharge.test(product.platform_charge);
    if (
      product.status === "Publish" &&
      (product.platform_charge === "" || product.platform_charge === null)
    ) {
      setErrors((prev) => ({
        ...prev,
        platform_charge: Alert.required,
      }));
      errorHas = true;
    } else if (
      product.platform_charge !== "" &&
      product.platform_charge != null &&
      !isCharge
    ) {
      setErrors((prev) => ({
        ...prev,
        platform_charge: Alert.invalidFormat,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        platform_charge: "",
      }));
    }

    if (errorHas === true) return;
    const payload = {
      product_price: product_price,
      current_market_price: current_market_price,
      gst: product.gst,
      platform_charge_type: product.platform_charge_type,
      platform_charge: product.platform_charge,
    };

    setDisableCalculate(true);
    const response = await fetch(
      `${API_HOST}/${APIEndpoint.calculateSellingPrice}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    const res = await response.json();
    if (res.status === 200) {
      updateVariantField(index, "selling_price", res.data.selling_price);
    } else if (res.status === 403) {
      setInfoModalOpen(true);
      setModalMessage(res.message);
    } else if (res.status === 400) {
      const errors = res.errors;
      let errorMessage = "";
      if (errors) {
        Object.keys(errors).forEach((key) => {
          errors[key].forEach((message) => {
            errorMessage = message;
          });
        });
        showToast("error", errorMessage);
      }
    } else {
      showToast("info", "Something went wrong");
    }
    setDisableCalculate(false);
  };

  useEffect(() => {
    if (product.status !== "" && product.name !== "") {
      setEnableAddProductBtn(true);
    } else {
      setEnableAddProductBtn(false);
    }
  }, [product]);

  // Get product details
  const getProductDetails = async (productId) => {
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }
    const response = await fetch(
      `${API_HOST}/${APIEndpoint.crudInHouseProducts}/${productId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );

    const res = await response.json();
    if (res.status === 200) {
      let Data = res.data;

      if (Data) {
        setProduct(Data);
        setProduct((prev) => ({
          ...prev,
          status:
            Data.status === "Verified and Published" ? "Publish" : Data.status,

          name: Data.name,
          description: Data.description,
          category_id: Data.category_id,
          brand_id: Data.brand_id,
          condition: Data.condition,
          age_group_id: Data.age_group_id,
          platform_charge_type: Data.platform_charge_type,
          platform_charge: Data.platform_charge,
          gst: Data.gst,
          length: Data.length,
          width: Data.width,
          height: Data.height,
          weight: Data.weight,
          warehouse_id: Data.warehouse_id,
          is_donatable: Data.is_donatable,
          is_negotiable: Data.is_negotiable,
          hsn: Data.hsn,
          meta_title: Data.meta_title,
          meta_description: Data.meta_description,
        }));

        if (Data.stocks && res.data.stocks.length > 0) {
          let stocks = res.data.stocks;
          setVariantsProductList([]);
          stocks.forEach(async (product, index) => {
            await addNewVariant();
            await updateVariantField(index, "is_default", product.is_default);
            await updateVariantField(index, "stock_id", product.id);
            await updateVariantField(index, "quantity", product.quantity);
            await updateVariantField(index, "video_url", product.video_url);
            await updateVariantField(
              index,
              "product_price",
              product.product_price
            );
            await updateVariantField(
              index,
              "current_market_price",
              product.current_market_price
            );
            await updateVariantField(
              index,
              "selling_price",
              product.selling_price
            );
            await updateVariantField(index, "sku", product.sku);
            await updateVariantField(index, "barcode", product.barcode);

            // Update images
            await updateVariantField(index, "thumb_image", product.thumb_image);
            await updateVariantField(
              index,
              "front_image",
              product.images_url[0]
            );
            await updateVariantField(
              index,
              "left_image",
              product.images_url[1]
            );
            await updateVariantField(
              index,
              "right_image",
              product.images_url[2]
            );
            await updateVariantField(
              index,
              "back_image",
              product.images_url[3]
            );
            await updateVariantField(index, "top_image", product.images_url[4]);

            if (product.variant_id && product.variant_value_id) {
              getSelectedVarient(
                index,
                product.variant_id,
                product.variant_value_id
              );
            }
          });
        }
      }
    } else {
      console.error(res);
    }
  };

  // image handling
  const [prodImageToEdit, setProdImageToEdit] = useState({
    imgFile: null,
    imgName: null,
    imgNameError: null,
    index: null,
  });
  const [showImgUpModal, setShowImgUpModal] = useState(false);
  const [rawImgName, setRawImgName] = useState("");
  const [rawImage, setRawImage] = useState(null);
  const cropperRef = useRef(null);

  const resetInputType = (imgName) => {
    document.getElementById(imgName).value = "";
  };

  const isBase64Image = (value) => {
    return typeof value === "object";
  };

  const loadImgOnModal = (imgName, img) => {
    setShowImgUpModal(true);
    setRawImgName(imgName);
    setRawImage(URL.createObjectURL(img));
  };

  const convertBase64ToFile = (base64Image, file) => {
    if (base64Image.startsWith("data:image/")) {
      const binaryString = atob(base64Image.split(",")[1]);
      const arrayBuffer = new ArrayBuffer(binaryString.length);
      const byteArray = new Uint8Array(arrayBuffer);
      for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
      }
      const fileSize = arrayBuffer.byteLength;
      const customFile = new File([arrayBuffer], file.name, {
        type: file.type,
        lastModified: file.lastModified,
      });
      Object.defineProperty(customFile, "size", {
        value: fileSize,
      });

      return customFile;
    }
  };

  const cropImage = async () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const croppedImg = cropperRef.current?.cropper
        .getCroppedCanvas()
        .toDataURL();

      const customFile = convertBase64ToFile(
        croppedImg,
        prodImageToEdit.imgFile
      );
      if (customFile) {
        await updateVariantField(
          prodImageToEdit.index,
          prodImageToEdit.imgName,
          customFile
        );
      } else {
        await updateVariantField(
          prodImageToEdit.index,
          prodImageToEdit.imgNameError,
          "Invalid image format"
        );
      }
    }
    // reset modal
    setShowImgUpModal(false);
    setRawImgName("");
    setRawImage(null);
    setProdImageToEdit((prev) => ({
      ...prev,
      imgFile: null,
      imgName: null,
      imgNameError: null,
      index: null,
    }));
  };

  const compressAndResizeImage = (
    file,
    imgName,
    name,
    errorName,
    index,
    stock_id
  ) => {
    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB
    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Set dimensions to 600x600
        canvas.width = 600;
        canvas.height = 600;

        // Calculate scaling factors to fit image within 600x600
        const scaleFactor = Math.min(600 / img.width, 600 / img.height);
        const newWidth = img.width * scaleFactor;
        const newHeight = img.height * scaleFactor;

        // Draw image on canvas with new dimensions
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        canvas.toBlob(
          (blob) => {
            // Check compressed image size
            if (blob.size > maxSizeInBytes) {
              setErrors((prev) => ({
                ...prev,
                [imgName]: "Image size must be up to 2 MB after compression.",
              }));
              resetInputType(imgName);
              return;
            }

            // Display compressed image
            const compressedImg = new File([blob], file.name, {
              type: file.type,
            });
            const reader = new FileReader();
            reader.onload = async () => {
              await updateVariantField(index, name, compressedImg);

              await updateVariantField(index, errorName, "");
              if (page == "edit") {
                if (stock_id !== null) {
                  await updateVariantField(index, "update_stock", true);
                } else {
                  await updateVariantField(index, "new_stock", true);
                }
              }
            };
            reader.readAsDataURL(compressedImg);
          },
          file.type,
          0.7 // Compression quality (0.7 means 70% quality)
        );
      };
    };

    reader.readAsDataURL(file);
  };

  // image upload functions start here
  const handleImageUpload = (imgName, name, errorName, index, stock) => {
    const ele = document.getElementById(imgName);
    // ele.click();

    ele.addEventListener("change", async (event) => {
      const file = event.target.files[0];

      if (file === undefined) return;

      // Check file extension
      const allowedExtensions = ["png", "jpg", "jpeg", "webp"];
      const extension = file.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(extension)) {
        await updateVariantField(
          index,
          errorName,
          "Please upload an image with .png, .jpg, .jpeg, or .webp extension."
        );
        resetInputType(imgName);

        // alert("returning !allowedExtensions");
        return;
      }

      // Compress and resize image
      compressAndResizeImage(file, imgName, name, errorName, index, stock);
    });
  };

  // Featch Category API TO show List of All Category Element;
  const [categoryData, setCategoryData] = useState([]);
  const getAllCategoriesData = async () => {
    await fetch(`${API_HOST}/api/categories`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let dataArray = data.data;
        if (Array.isArray(dataArray)) {
          setCategoryData(dataArray);
        } else {
          console.error("Data is not available");
        }
      })

      .catch((err) => console.error(err));
  };

  // Featch Brand API TO show List of All Brand List;
  const [brandData, setBrandData] = useState([]);

  const getAllBrandList = async () => {
    const token = getDataFromCookies("7b9KpQ3rW6");
    await fetch(`${API_HOST}/${APIEndpoint.getBrandsList}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const dataArray = data.data;
        if (Array.isArray(dataArray)) {
          const sortedArray = dataArray.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          setBrandData(sortedArray);
        } else {
          console.error("Data is not available");
        }
      })
      .catch((err) => console.error(err));
  };

  // Featch Conditions API TO show List of All Conditions List;
  const [conditionData, setConditionData] = useState([]);

  const getAllConditionsList = async () => {
    await fetch(`${API_HOST}/api/conditions`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const dataArray = data.data;
        if (Array.isArray(dataArray)) {
          const titlesArray = dataArray.map((item) => item.title);
          setConditionData(titlesArray);
        } else {
          console.error("Data is not available");
        }
      })
      .catch((err) => console.error(err));
  };

  // Featch Conditions API TO show List of All Conditions List;
  const [applicableAgeGroupData, setapplicableAgeGroupData] = useState([]);
  const getAllAplplicableAgeGroupList = async () => {
    const response = await fetch(`${API_HOST}/api/applicable-age-groups`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const res = await response.json();
    if (res.status === 200) {
      setapplicableAgeGroupData(res.data);
    }
  };

  const [gstPercentage, setGstPercentage] = useState([]);
  const getGSTpercentage = async () => {
    await fetch(`${API_HOST}/api/gst-percents`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setGstPercentage(data.data);
      })
      .catch((err) => console.error(err));
  };

  let authenticationToken = getDataFromCookies("7b9KpQ3rW6");
  const [warehouseOptionsValue, setWarehouseOptionsValue] = useState([]);
  const getWereHouseAddress = async () => {
    await fetch(`${API_HOST}/api/admin/warehouses`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authenticationToken}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const dataArray = data.data;
        if (Array.isArray(dataArray)) {
          setWarehouseOptionsValue(dataArray);
        } else {
          console.error("Data is not available");
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    contextObj.setInput("isNavigateFromProducts", true);
    getAllCategoriesData();
    fetchVariantsData(variantsURL);
    getAllBrandList();
    getAllConditionsList();
    getAllAplplicableAgeGroupList();
    getGSTpercentage();
    getWereHouseAddress();
  }, []);

  const [imagesURL, setImagesURL] = useState({
    font_image: null,
    left_image: null,
    right_image: null,
    back_image: null,
    top_image: null,
  });

  // update product API call
  const updateProduct = async (formData) => {
    setLoadingButton(true);
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }
    formData.append("_method", "PUT");

    const response = await fetch(
      `${API_HOST}/${APIEndpoint.crudInHouseProducts}/${productId}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
    const res = await response.json();
    if (res.status === 200) {
      showToast("success", res.message);
      window.history.back();
    } else if (
      res.status === 400 &&
      res.message &&
      res.message.includes("sku")
    ) {
      setErrors((prev) => ({
        ...prev,
        sku: res.message,
      }));
    } else if (res.status === 400 || res.status === 403) {
      const errors = res.errors;
      let errorMessage = "";
      if (res.message) errorMessage = res.message;
      if (errors) {
        Object.keys(errors).forEach((key) => {
          let resError = null;

          if (key !== "stocks" && key.includes("stocks")) {
            resError = key.split(".");
          }

          if (key !== "stocks" && !key.includes("stocks")) {
            errors[key].forEach((message) => {
              errorMessage = message;
              setErrors((prev) => ({
                ...prev,
                [key]: message,
              }));
            });
          } else if (resError) {
            errors[key].forEach(async (message) => {
              if (message.includes(key)) {
                const formattedKey = resError[2].split("_").join(" ");
                message = message.replace(new RegExp(key, "g"), formattedKey);
              }
              await updateVariantField(
                resError[1],
                `${resError[2]}_error`,
                message
              );
            });
          }
        });
      }
      if (errorMessage.includes("sku")) {
        setErrors((prev) => ({
          ...prev,
          sku: errorMessage,
        }));
      } else {
        showToast("error", errorMessage);
      }
    } else if (res.status === 404) {
      showToast("error", res.message);
    }
    setLoadingButton(false);
  };

  const createProduct = async (formData) => {
    setLoadingButton(true);
    const url = `${API_HOST}/${APIEndpoint.createInHouseProduct}`;
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    const res = await response.json();
    if (res.status === 201) {
      showToast("success", res.message);
      //clear all fields
      setProduct({
        name: "",
        status: "",
        description: "",
        category_id: "",
        brand_id: "",
        condition: "",
        product_price: "",
        current_market_price: "",
        age_group_id: "",
        thumb_image: null,
        video_url: "",
        platform_charge_type: "",
        platform_charge: "",
        gst: "",
        length: "",
        width: "",
        height: "",
        weight: "",
        warehouse_id: "",
        is_donatable: 0,
        is_negotiable: 0,
        sku: "",
        barcode: "",
        hsn: "",
        status: "Publish",
      });
      navigate(Navigation.adminProducts);
    } else if (res.status === 400 || res.status === 403) {
      const errors = res.errors;
      let errorMessage = "";
      if (res.message) errorMessage = res.message;
      if (errors) {
        Object.keys(errors).forEach((key) => {
          let resError = null;

          if (key !== "stocks" && key.includes("stocks")) {
            resError = key.split(".");
          }

          if (key !== "stocks" && !key.includes("stocks")) {
            errors[key].forEach((message) => {
              errorMessage = message;
              setErrors((prev) => ({
                ...prev,
                [key]: message,
              }));
            });
          } else if (resError && resError.length == 3) {
            errors[key].forEach(async (message) => {
              if (message.includes(key)) {
                const formattedKey = resError[2].split("_").join(" ");
                message = message.replace(new RegExp(key, "g"), formattedKey);
              }
              await updateVariantField(
                resError[1],
                `${resError[2]}_error`,
                message
              );
            });
          }
        });
      }
      if (errorMessage.includes("sku")) {
        setErrors((prev) => ({
          ...prev,
          sku: errorMessage,
        }));
      } else {
        showToast("error", errorMessage);
      }
    } else if (
      response.status === 401 &&
      response.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      navigate(Navigation.login);
      showToast("info", "Sessions expired!");
    } else if (response.status === 401 && response.message !== "") {
      showToast("error", response.message);
    } else {
      showToast("info", "Something went wrong");
    }
    setLoadingButton(false);
  };
  const convertToAmpersand = (text) => {
    // Replace blank spaces with "&nbsp;" and line breaks with "<br>"
    return text.replace(/\s/g, "&nbsp;").replace(/\n/g, "<br>");
  };
  const handleButtonSubmit = async () => {
    let errorHas = false;

    // Name validation
    if (product.name === "" || product.name === null) {
      setErrors((prev) => ({
        ...prev,
        name: Alert.required,
      }));
      errorHas = true;
    } else if (product.name.length > 150 || product.name.length < 3) {
      setErrors((prev) => ({
        ...prev,
        name: Alert.charBetween3To150,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        name: "",
      }));
    }

    // Description validation
    const emptyHTMLTags = (input) => {
      var emptyTagPattern =
        /<(p|h1|h2|h3|div|span|b|u|i|a|li|ul|bq)\s*>\s*<br\s*>\s*<\/\1>\s*/gi;
      return emptyTagPattern.test(input);
    };
    if (
      product.status === "Publish" &&
      (product.description === "" || product.description == null)
    ) {
      setErrors((prev) => ({
        ...prev,
        description: Alert.required,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        description: "",
      }));
    }

    variantsProductList &&
      variantsProductList.forEach(async (variantsProduct, index) => {
        // all images validation

        if (
          product.status === "Publish" &&
          (variantsProduct.thumb_image === "null" ||
            variantsProduct.thumb_image === null ||
            variantsProduct.thumb_image === undefined)
        ) {
          await updateVariantField(index, "thumb_image_error", Alert.required);
          errorHas = true;
        } else {
          await updateVariantField(index, "thumb_image_error", "");
        }
        if (
          product.status === "Publish" &&
          (variantsProduct.front_image === "null" ||
            variantsProduct.front_image === null ||
            variantsProduct.front_image === undefined)
        ) {
          await updateVariantField(index, "front_image_error", Alert.required);
          errorHas = true;
        } else {
          await updateVariantField(index, "front_image_error", "");
        }
        if (
          product.status === "Publish" &&
          (variantsProduct.left_image === "null" ||
            variantsProduct.left_image === null ||
            variantsProduct.left_image === undefined)
        ) {
          await updateVariantField(index, "left_image_error", Alert.required);
          errorHas = true;
        } else {
          await updateVariantField(index, "left_image_error", "");
        }
        if (
          product.status === "Publish" &&
          (variantsProduct.right_image === "null" ||
            variantsProduct.right_image === null ||
            variantsProduct.right_image === undefined)
        ) {
          await updateVariantField(index, "right_image_error", Alert.required);
          errorHas = true;
        } else {
          await updateVariantField(index, "right_image_error", "");
        }
        if (
          product.status === "Publish" &&
          (variantsProduct.back_image === "null" ||
            variantsProduct.back_image === null ||
            variantsProduct.back_image === undefined)
        ) {
          await updateVariantField(index, "back_image_error", Alert.required);
          errorHas = true;
        } else {
          await updateVariantField(index, "back_image_error", "");
        }
        if (
          product.status === "Publish" &&
          (variantsProduct.top_image === "null" ||
            variantsProduct.top_image === null ||
            variantsProduct.top_image === undefined)
        ) {
          await updateVariantField(index, "top_image_error", Alert.required);
          errorHas = true;
        } else {
          await updateVariantField(index, "top_image_error", "");
        }

        // product price error
        const productPrice = /^[0-9]+$/;
        const isPrice = productPrice.test(variantsProduct.product_price);
        if (
          product.status === "Publish" &&
          (variantsProduct.product_price === "" ||
            variantsProduct.product_price === null)
        ) {
          await updateVariantField(
            index,
            "product_price_error",
            Alert.required
          );
          errorHas = true;
        } else if (
          variantsProduct.product_price !== "" &&
          variantsProduct.product_price != null &&
          !isPrice
        ) {
          await updateVariantField(
            index,
            "product_price_error",
            Alert.invalidFormat
          );
          errorHas = true;
        } else if (
          variantsProduct.product_price !== "" &&
          variantsProduct.product_price != null &&
          (variantsProduct.product_price > 100000 ||
            variantsProduct.product_price < 100)
        ) {
          await updateVariantField(
            index,
            "product_price_error",
            Alert.valBetween100To100000
          );
          errorHas = true;
        } else {
          await updateVariantField(index, "product_price_error", "");
        }

        // current_market_price validation
        const marketPrice = /^[0-9]+$/;
        const isOriginalPrice = marketPrice.test(
          variantsProduct.current_market_price
        );
        if (
          product.status === "Publish" &&
          (variantsProduct.current_market_price === "" ||
            variantsProduct.current_market_price === null)
        ) {
          await updateVariantField(
            index,
            "current_market_price_error",
            Alert.required
          );
          errorHas = true;
        } else if (
          variantsProduct.current_market_price !== "" &&
          variantsProduct.current_market_price != null &&
          !isOriginalPrice
        ) {
          await updateVariantField(
            index,
            "current_market_price_error",
            Alert.invalidFormat
          );
          errorHas = true;
        } else if (
          variantsProduct.current_market_price !== "" &&
          variantsProduct.current_market_price != null &&
          (variantsProduct.current_market_price > 200000 ||
            variantsProduct.current_market_price < 200)
        ) {
          await updateVariantField(
            index,
            "current_market_price_error",
            Alert.valBetween200To200000
          );
          errorHas = true;
        } else {
          await updateVariantField(index, "current_market_price_error", "");
        }

        // quantity
        if (
          product.status === "Publish" &&
          variantsProductList.length > 1 &&
          (variantsProduct.quantity === "" || variantsProduct.quantity === null)
        ) {
          await updateVariantField(index, "quantity_error", Alert.required);
          errorHas = true;
        } else {
          await updateVariantField(index, "quantity_error", "");
        }
        // Variant
        if (
          product.status === "Publish" &&
          (variantsProduct.selectedVariant === "" ||
            variantsProduct.selectedVariant == null)
        ) {
          await updateVariantField(
            index,
            "selectedVariant_error",
            Alert.required
          );
          errorHas = true;
        } else {
          await updateVariantField(index, "selectedVariant_error", "");
        }
        // Variant Value
        if (
          product.status === "Publish" &&
          variantsProductList.length > 1 &&
          (variantsProduct.variant_values === "" ||
            variantsProduct.variant_values === null)
        ) {
          await updateVariantField(
            index,
            "variant_values_error",
            Alert.required
          );
          errorHas = true;
        } else {
          await updateVariantField(index, "variant_values_error", "");
        }
      });

    // GST Percentage validation
    if (
      product.status === "Publish" &&
      (product.gst === "" || product.gst === null)
    ) {
      setErrors((prev) => ({
        ...prev,
        gst: Alert.required,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        gst: "",
      }));
    }

    // platform charges validation
    const productPlatformCharge = /^[0-9]\d*$/;
    const isCharge = productPlatformCharge.test(product.platform_charge);
    if (
      product.status === "Publish" &&
      (product.platform_charge === "" || product.platform_charge === null)
    ) {
      setErrors((prev) => ({
        ...prev,
        platform_charge: Alert.required,
      }));
      errorHas = true;
    } else if (
      product.platform_charge !== "" &&
      product.platform_charge != null &&
      !isCharge
    ) {
      setErrors((prev) => ({
        ...prev,
        platform_charge: Alert.invalidFormat,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        platform_charge: "",
      }));
    }

    // Warehouse validation
    if (
      product.status === "Publish" &&
      (product.warehouse_id === "" || product.warehouse_id == null)
    ) {
      setErrors((prev) => ({
        ...prev,
        warehouse_id: Alert.required,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        warehouse_id: "",
      }));
    }

    // length error
    const isValidNumeric = (value) => {
      const numeric = /^[0-9]+$/;
      const isValid = numeric.test(value);
      return isValid;
    };
    if (
      product.status === "Publish" &&
      (product.length === "" || product.length === null)
    ) {
      setErrors((prev) => ({
        ...prev,
        length: Alert.required,
      }));
      errorHas = true;
    } else if (
      product.length !== "" &&
      product.length != null &&
      !isValidNumeric(product.length)
    ) {
      setErrors((prev) => ({
        ...prev,
        length: Alert.invalidFormat,
      }));
      errorHas = true;
    } else if (
      product.length !== "" &&
      product.length != null &&
      (product.length === 0 ||
        product.length === "0" ||
        product.length < 0.1 ||
        product.length > 300)
    ) {
      setErrors((prev) => ({
        ...prev,
        length: Alert.valBetweenPoint1To300,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        length: "",
      }));
    }

    // width error
    if (
      product.status === "Publish" &&
      (product.width === "" || product.width === null)
    ) {
      setErrors((prev) => ({
        ...prev,
        width: Alert.required,
      }));
      errorHas = true;
    } else if (
      product.width !== "" &&
      product.width != null &&
      !isValidNumeric(product.width)
    ) {
      setErrors((prev) => ({
        ...prev,
        width: Alert.invalidFormat,
      }));
      errorHas = true;
    } else if (
      product.width !== "" &&
      product.width != null &&
      !isValidNumeric(product.width) &&
      (product.width === 0 ||
        product.width === "0" ||
        product.width < 0.1 ||
        product.width > 300)
    ) {
      setErrors((prev) => ({
        ...prev,
        width: Alert.valBetweenPoint1To300,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        width: "",
      }));
    }

    // height error
    if (
      product.status === "Publish" &&
      (product.height === "" || product.height === null)
    ) {
      setErrors((prev) => ({
        ...prev,
        height: Alert.required,
      }));
      errorHas = true;
    } else if (
      product.height !== "" &&
      product.height != null &&
      !isValidNumeric(product.height)
    ) {
      setErrors((prev) => ({
        ...prev,
        height: Alert.invalidFormat,
      }));
      errorHas = true;
    } else if (
      product.height !== "" &&
      product.height != null &&
      (product.height === 0 ||
        product.height === "0" ||
        product.height < 0.1 ||
        product.height > 300)
    ) {
      setErrors((prev) => ({
        ...prev,
        height: Alert.valBetweenPoint1To300,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        height: "",
      }));
    }

    // weight validation
    if (
      product.status === "Publish" &&
      (product.weight === "" || product.weight === null)
    ) {
      setErrors((prev) => ({
        ...prev,
        weight: Alert.required,
      }));
      errorHas = true;
    } else if (
      product.weight !== "" &&
      product.weight != null &&
      (product.weight === 0 || product.weight === "0" || product.weight > 20)
    ) {
      setErrors((prev) => ({
        ...prev,
        weight: Alert.valBetweenPoint1To20,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        weight: "",
      }));
    }

    // Category validation
    if (
      product.status === "Publish" &&
      (product.category_id === "" || product.category_id === null)
    ) {
      setErrors((prev) => ({
        ...prev,
        category_id: Alert.required,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        category_id: "",
      }));
    }
    // Brand validation
    if (
      product.status === "Publish" &&
      (product.brand_id === "" || product.brand_id === null)
    ) {
      setErrors((prev) => ({
        ...prev,
        brand_id: Alert.required,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        brand_id: "",
      }));
    }

    // Condition
    if (
      product.status === "Publish" &&
      (product.condition === "" || product.condition === null)
    ) {
      setErrors((prev) => ({
        ...prev,
        condition: Alert.required,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        condition: "",
      }));
    }

    // Age group validation
    if (
      product.status === "Publish" &&
      (product.age_group_id === "" || product.age_group_id === null)
    ) {
      setErrors((prev) => ({
        ...prev,
        age_group_id: Alert.required,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        age_group_id: "",
      }));
    }

    if (errorHas === true) return;

    // mandatory fields
    const formData = new FormData();

    // Add meta title and description
    if (product.meta_title) {
      formData.append("meta_title", product.meta_title);
    } else {
      formData.append(
        "meta_title",
        product.name.substring(0, metaTitleMaxLength)
      );
    }
    if (product.meta_description) {
      formData.append("meta_description", product.meta_description);
    } else if (product.description) {
      formData.append("meta_description", removeHtmlTags(product.description));
    }

    formData.append("name", product.name);

    if (product.status === "Publish") {
      formData.append("status", "Active");
    } else {
      formData.append("status", "Draft");
    }

    // Optional fields
    if (product.category_id) {
      formData.append("category_id", product.category_id);
    }
    if (product.condition) {
      formData.append("condition", product.condition);
    }

    if (product.age_group_id) {
      formData.append("age_group_id", product.age_group_id);
    }

    if (product.length) {
      formData.append("length", product.length);
    }
    if (product.width) {
      formData.append("width", product.width);
    }
    if (product.height) {
      formData.append("height", product.height);
    }
    if (product.weight) {
      formData.append("weight", product.weight);
    }
    if (product.is_donatable) {
      formData.append("is_donatable", product.is_donatable);
    }
    if (product.is_negotiable) {
      formData.append("is_negotiable", product.is_negotiable);
    }

    if (product.gst >= 0) {
      formData.append("gst", product.gst);
    }
    if (product.platform_charge_type) {
      formData.append("platform_charge_type", product.platform_charge_type);
    }
    if (product.platform_charge) {
      formData.append("platform_charge", product.platform_charge);
    }
    if (product.warehouse_id) {
      formData.append("warehouse_id", product.warehouse_id);
    }
    if (product.sku) {
      formData.append("sku", product.sku);
    }
    if (product.barcode) {
      formData.append("barcode", product.barcode);
    }
    if (product.hsn) {
      formData.append("hsn", product.hsn);
    }
    if (product.description) {
      const descriptionWithAmpersand = convertToAmpersand(product.description);
      formData.append("description", descriptionWithAmpersand);
    }
    if (product.brand_id) {
      formData.append("brand_id", product.brand_id);
    }

    let stock_update = 0;
    let stock_add = 0;

    let count = 0;
    let has_variant = 0;

    variantsProductList &&
      variantsProductList.forEach((variant, index) => {
        let title = "";
        if (pageName === "New") {
          title = `stocks[${index}]`;
        } else {
          formData.append("product_update", productUpdate);
          if (variant.new_stock === true && variant.update_stock === false) {
            stock_add = 1;
            title = `new_stock[${count}]`;
          }
          if (variant.update_stock === true && variant.new_stock === false) {
            stock_update = 1;
            title = `update_stock[${variant.stock_id}]`;
          }

          if (variant.stock_id) {
            formData.append(`${title}[stock_id]`, variant.stock_id);
          }
          formData.append(`${title}[quantity]`, variant.quantity);
        }

        if (title !== "") {
          if (variant.selectedVariant) {
            formData.append(`${title}[variant_id]`, variant.selectedVariant.id);
            if (variant.variant_values) {
              formData.append(
                `${title}[variant_value_id]`,
                variant.variant_values.id
              );
              has_variant = 1;
            }
          }
          formData.append(`${title}[is_default]`, variant.is_default);
          formData.append(`${title}[thumb_image]`, variant.thumb_image);
          formData.append(`${title}[front_image]`, variant.front_image);
          formData.append(`${title}[left_image]`, variant.left_image);
          formData.append(`${title}[right_image]`, variant.right_image);
          formData.append(`${title}[back_image]`, variant.back_image);
          formData.append(`${title}[top_image]`, variant.top_image);

          formData.append(`${title}[product_price]`, variant.product_price);
          formData.append(
            `${title}[current_market_price]`,
            variant.current_market_price
          );
          formData.append(`${title}[quantity]`, variant.quantity);
          formData.append(
            `${title}[video_url]`,
            variant.video_url ? variant.video_url : ""
          );
          formData.append(`${title}[sku]`, variant.sku ? variant.sku : "");
          formData.append(
            `${title}[barcode]`,
            variant.barcode ? variant.barcode : ""
          );
        }
        if (variant.new_stock === true) {
          count++;
        }
      });
    formData.append("has_variant", has_variant);
    formData.append("stock_update", stock_update);
    formData.append("stock_add", stock_add);

    if (pageName === "New") {
      createProduct(formData);
    } else {
      updateProduct(formData);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "thumb_image") {
      const file = event.target.files[0];
      setProduct((prevData) => ({
        ...prevData,
        [name]: file,
      }));
    } else if (
      name === "font_image" ||
      name === "left_image" ||
      name === "right_image" ||
      name === "back_image" ||
      name === "top_image"
    ) {
      const file = event.target.files[0];
      setImagesURL((prevData) => ({
        ...prevData,
        [name]: file,
      }));
    } else {
      setProduct((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      setErrors((prevData) => ({
        ...prevData,
        [name]: "",
      }));
    }
    setProductUpdate(1);
  };

  const handleDescriptionChange = (value) => {
    setProduct((prev) => ({
      ...prev,
      description: value,
    }));
    setErrors((prev) => ({
      ...prev,
      description: "",
    }));
  };

  // character "e"  not allowed in input types numbers
  const handleKeyPress = (e) => {
    if (e.keyCode === 69 || e.key === "e" || e.key === "+" || e.key === "-") {
      e.preventDefault();
    }
  };

  const dropdownRef = useRef(null);
  const dropdownContainerRef = useRef(null);
  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownContainerRef.current.contains(event.target) &&
      !dropdownRef.current.contains(event.target)
    ) {
      closeAllVariantDropDown();
      setUpdateVariantList(true);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [updateVariantList, setUpdateVariantList] = useState(false);
  useEffect(() => {
    if (updateVariantList) {
      closeAllVariantDropDown();
    }
  }, [variantsProductList, updateVariantList]);
  const closeAllVariantDropDown = () => {
    setUpdateVariantList(false);

    variantsProductList &&
      variantsProductList.map(async (variantProduct, index) => {
        await updateVariantField(index, "variantListShow", false);
        await updateVariantField(index, "variantValuesListShow", false);
      });
  };

  return (
    <div className="page-data">
      {showRemoveConfirmation.modal && (
        <div className="modal fade">
          <div className="modal-content">
            <div className="modal-header">
              <p className="modal-heading">Remove Variant</p>
              <button
                type="button"
                className="order modal-close-icon"
                onClick={cancelRemoveVariant}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to remove variant?</p>
            </div>

            <div className="modal-buttons">
              <button
                type="button"
                className="btn secondary-btn"
                onClick={cancelRemoveVariant}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn primary-btn"
                onClick={() => {
                  filterVariant(showRemoveConfirmation.variantIndex);
                }}
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="page-heading">
        <div className="breadcrumb">
          <li
            onClick={() => {
              window.history.back();
            }}
          >
            Products
          </li>
          <li className="active">{pageName} Product</li>
        </div>
        <button
          type="button"
          onClick={() => {
            window.history.back();
          }}
          className="primary-btn"
        >
          Product List
        </button>
      </div>

      <div className="page-container">
        <div className="page-content">
          <div className="left-area">
            <div className="small-box">
              <label>
                Product Name <span className="red">*</span>
              </label>
              <input
                type="text"
                name="name"
                maxLength="150"
                value={product.name}
                onChange={handleInputChange}
                className="form-control"
              />
              {errors.name && <label className="red">{errors.name}</label>}
              <label className="mt-10">
                Description{" "}
                {product.status === "Publish" && <span className="red">*</span>}
              </label>
              <ReactQuill
                name="description"
                value={product.description}
                onChange={handleDescriptionChange}
                theme="snow"
                style={{ height: "fitContent" }}
                className="custom-react-quill"
              />
              {errors.description && (
                <label className="red">{errors.description}</label>
              )}
            </div>

            {/* ====================== map in variant list start ========================= */}
            <div className="prodVariantsContainer">
              {variantsProductList &&
                variantsProductList.map((variantProduct, index) => {
                  return (
                    <div className="small-box">
                      <div>
                        <div className="addProdVariantCheckboxContainer">
                          <div className="">
                            <label className="checkbox-label isDefaultLabel">
                              <input
                                checked={variantProduct.is_default === 1}
                                type="checkbox"
                                id="product-price-first"
                                name="priceOption"
                                value="product_price_as_first"
                                onClick={async () => {
                                  await updateVariantField(
                                    index,
                                    variantProduct.stock_id == null
                                      ? "new_stock"
                                      : "update_stock",
                                    true
                                  );

                                  markDefaultProduct(index);
                                }}
                              />
                              Show This Variant In Product List Page
                            </label>
                          </div>
                          {index !== 0 ? (
                            <div className="prodVariantCheckbox">
                              <label className="checkbox-label isDefaultLabel">
                                <input
                                  checked={
                                    variantProduct.product_price_as_first
                                  }
                                  type="checkbox"
                                  id="product-price-first"
                                  name="priceOption"
                                  value="product_price_as_first"
                                  onClick={async () => {
                                    if (
                                      variantProduct.product_price_as_first ===
                                      true
                                    ) {
                                      await updateVariantField(
                                        index,
                                        "product_price_as_first",
                                        false
                                      );
                                    } else {
                                      checkFirstVariantPrice(
                                        index,
                                        "product_price_as_first"
                                      );
                                    }
                                  }}
                                />
                                Product Price as First
                              </label>

                              <label className="checkbox-label isDefaultLabel">
                                <input
                                  checked={
                                    variantProduct.current_market_price_as_first
                                  }
                                  type="checkbox"
                                  id="market-price-first"
                                  name="priceOption"
                                  value="current_market_price_as_first"
                                  onClick={async () => {
                                    if (
                                      variantProduct.current_market_price_as_first ===
                                      true
                                    ) {
                                      await updateVariantField(
                                        index,
                                        "current_market_price_as_first",
                                        false
                                      );
                                    } else {
                                      checkFirstVariantPrice(
                                        index,
                                        "current_market_price_as_first"
                                      );
                                    }
                                  }}
                                />
                                Current Market Price as First
                              </label>
                            </div>
                          ) : (
                            <div></div>
                          )}

                          <button
                            className="addRemoveProdVariantBtn"
                            onClick={() => {
                              setShowRemoveConfirmation((prev) => ({
                                ...prev,
                                modal: true,
                                variantIndex: index,
                                stock_id: variantProduct.stock_id,
                              }));
                            }}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} /> Remove
                          </button>
                        </div>
                      </div>
                      <div className="small-box">
                        <div className="product-images">
                          <div>
                            <label>
                              Thumbnail{" "}
                              {product.status === "Publish" && (
                                <span className="red">*</span>
                              )}
                            </label>
                            <div
                              onClick={() => {
                                handleImageUpload(
                                  `${index}_thumb_image`,
                                  "thumb_image",
                                  "thumb_image_error",
                                  index,
                                  variantProduct.stock_id
                                );
                              }}
                              className={
                                "image-dropzone " +
                                (variantProduct.thumb_image &&
                                  "dropzone-uploaded")
                              }
                            >
                              {variantProduct.thumb_image ? (
                                <>
                                  <img
                                    className="variantProdImgs product-image"
                                    src={
                                      variantProduct.thumb_image instanceof File
                                        ? URL.createObjectURL(
                                            variantProduct.thumb_image
                                          )
                                        : `${API_HOST}/${variantProduct.thumb_image}`
                                    }
                                    loading="lazy"
                                  />
                                  <div
                                    className="img-btn-action"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                    }}
                                  >
                                    <div className="img-btns">
                                      {isBase64Image(
                                        variantProduct.thumb_image
                                      ) && (
                                        <button
                                          type="button"
                                          className="image-edit-icon"
                                          onClick={(e) => {
                                            loadImgOnModal(
                                              "thumb_image",
                                              variantProduct.thumb_image
                                            );
                                            setProdImageToEdit((prev) => ({
                                              ...prev,
                                              imgFile:
                                                variantProduct.thumb_image,
                                              imgName: "thumb_image",
                                              imgNameError:
                                                variantProduct.thumb_image_error,
                                              index: index,
                                            }));
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faEdit} />
                                          <span>Edit</span>
                                        </button>
                                      )}
                                      <button
                                        type="button"
                                        className="image-remove-icon"
                                        onClick={async (e) => {
                                          e.stopPropagation();
                                          await updateVariantField(
                                            index,
                                            "thumb_image",
                                            null
                                          );
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <p className="click-to-upload">
                                  Click to upload
                                </p>
                              )}
                              <input
                                key={`${index}_thumb_image`}
                                id={`${index}_thumb_image`}
                                type="file"
                                accept=".png, .jpg, .jpeg, .webp"
                                name="thumb_image"
                                onChange={async (e) => {
                                  await updateVariantField(
                                    index,
                                    "thumb_image",
                                    e.target.files[0]
                                  );
                                  await updateVariantField(
                                    index,
                                    "thumb_image_error",
                                    ""
                                  );
                                }}
                                style={{ opacity: 0 }}
                              />
                            </div>
                            {variantProduct.thumb_image_error && (
                              <label className="red ">
                                {variantProduct.thumb_image_error}
                              </label>
                            )}
                          </div>
                          <div>
                            <label>
                              Front View{" "}
                              {product.status === "Publish" && (
                                <span className="red">*</span>
                              )}
                            </label>
                            <div
                              onClick={() => {
                                handleImageUpload(
                                  `${index}_front_image`,
                                  "front_image",
                                  "front_image_error",
                                  index,
                                  variantProduct.stock_id
                                );
                              }}
                              className={
                                "image-dropzone " +
                                (variantProduct.front_image &&
                                  "dropzone-uploaded")
                              }
                            >
                              {variantProduct.front_image ? (
                                <>
                                  <img
                                    className="variantProdImgs product-image"
                                    src={
                                      variantProduct.front_image instanceof File
                                        ? URL.createObjectURL(
                                            variantProduct.front_image
                                          )
                                        : `${API_HOST}/${variantProduct.front_image}`
                                    }
                                    loading="lazy"
                                  />
                                  <div className="img-btn-action">
                                    <div className="img-btns">
                                      {isBase64Image(
                                        variantProduct.front_image
                                      ) && (
                                        <button
                                          type="button"
                                          className="image-edit-icon"
                                          onClick={() => {
                                            loadImgOnModal(
                                              "front_image",
                                              variantProduct.front_image
                                            );
                                            setProdImageToEdit((prev) => ({
                                              ...prev,
                                              imgFile:
                                                variantProduct.front_image,
                                              imgName: "front_image",
                                              imgNameError:
                                                variantProduct.front_image_error,
                                              index: index,
                                            }));
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faEdit} />
                                          <span>Edit</span>
                                        </button>
                                      )}
                                      <button
                                        type="button"
                                        className="image-remove-icon"
                                        onClick={async (e) => {
                                          e.stopPropagation();
                                          await updateVariantField(
                                            index,
                                            "front_image",
                                            null
                                          );
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <p className="click-to-upload">
                                  Click to upload
                                </p>
                              )}
                              <input
                                type="file"
                                accept=".png, .jpg, .jpeg, .webp"
                                name="front_image"
                                key={`${index}_front_image`}
                                id={`${index}_front_image`}
                                onChange={async (e) => {
                                  await updateVariantField(
                                    index,
                                    "front_image",
                                    e.target.files[0]
                                  );
                                  await updateVariantField(
                                    index,
                                    "front_image_error",
                                    ""
                                  );
                                }}
                                style={{ opacity: 0 }}
                              />
                            </div>
                            {variantProduct.front_image_error && (
                              <label className="red">
                                {variantProduct.front_image_error}
                              </label>
                            )}
                          </div>
                          <div>
                            <label>
                              Left View{" "}
                              {product.status === "Publish" && (
                                <span className="red">*</span>
                              )}
                            </label>
                            <div
                              onClick={() => {
                                handleImageUpload(
                                  `${index}_left_image`,
                                  "left_image",
                                  "left_image_error",
                                  index,
                                  variantProduct.stock_id
                                );
                              }}
                              className={
                                "image-dropzone " +
                                (variantProduct.left_image &&
                                  "dropzone-uploaded")
                              }
                            >
                              {variantProduct.left_image ? (
                                <>
                                  <img
                                    className="variantProdImgs product-image"
                                    src={
                                      variantProduct.left_image instanceof File
                                        ? URL.createObjectURL(
                                            variantProduct.left_image
                                          )
                                        : `${API_HOST}/${variantProduct.left_image}`
                                    }
                                    loading="lazy"
                                  />
                                  <div className="img-btn-action">
                                    <div className="img-btns">
                                      {isBase64Image(
                                        variantProduct.left_image
                                      ) && (
                                        <button
                                          type="button"
                                          className="image-edit-icon"
                                          onClick={() => {
                                            loadImgOnModal(
                                              "left_image",
                                              variantProduct.left_image
                                            );
                                            setProdImageToEdit((prev) => ({
                                              ...prev,
                                              imgFile:
                                                variantProduct.left_image,
                                              imgName: "left_image",
                                              imgNameError:
                                                variantProduct.left_image_error,
                                              index: index,
                                            }));
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faEdit} />
                                          <span>Edit</span>
                                        </button>
                                      )}
                                      <button
                                        type="button"
                                        className="image-remove-icon"
                                        onClick={async (e) => {
                                          e.stopPropagation();
                                          await updateVariantField(
                                            index,
                                            "left_image",
                                            null
                                          );
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <p className="click-to-upload">
                                  Click to upload
                                </p>
                              )}
                              <input
                                type="file"
                                accept=".png, .jpg, .jpeg, .webp"
                                name="left_image"
                                key={`${index}_left_image`}
                                id={`${index}_left_image`}
                                onChange={async (e) => {
                                  await updateVariantField(
                                    index,
                                    "left_image",
                                    e.target.files[0]
                                  );
                                  await updateVariantField(
                                    index,
                                    "left_image_error",
                                    ""
                                  );
                                }}
                                style={{ opacity: 0 }}
                              />
                            </div>
                            {variantProduct.left_image_error && (
                              <label className="red">
                                {variantProduct.left_image_error}
                              </label>
                            )}
                          </div>
                          <div>
                            <label>
                              Right View{" "}
                              {product.status === "Publish" && (
                                <span className="red">*</span>
                              )}
                            </label>
                            <div
                              onClick={() => {
                                handleImageUpload(
                                  `${index}_right_image`,
                                  "right_image",
                                  "right_image_error",
                                  index,
                                  variantProduct.stock_id
                                );
                              }}
                              className={
                                "image-dropzone " +
                                (variantProduct.right_image &&
                                  "dropzone-uploaded")
                              }
                            >
                              {variantProduct.right_image ? (
                                <>
                                  <img
                                    className="variantProdImgs product-image"
                                    src={
                                      variantProduct.right_image instanceof File
                                        ? URL.createObjectURL(
                                            variantProduct.right_image
                                          )
                                        : `${API_HOST}/${variantProduct.right_image}`
                                    }
                                    loading="lazy"
                                  />
                                  <div className="img-btn-action">
                                    <div className="img-btns">
                                      {isBase64Image(
                                        variantProduct.right_image
                                      ) && (
                                        <button
                                          type="button"
                                          className="image-edit-icon"
                                          onClick={() => {
                                            loadImgOnModal(
                                              "right_image",
                                              variantProduct.right_image
                                            );
                                            setProdImageToEdit((prev) => ({
                                              ...prev,
                                              imgFile:
                                                variantProduct.right_image,
                                              imgName: "right_image",
                                              imgNameError:
                                                variantProduct.right_image_error,
                                              index: index,
                                            }));
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faEdit} />
                                          <span>Edit</span>
                                        </button>
                                      )}
                                      <button
                                        type="button"
                                        className="image-remove-icon"
                                        onClick={async (e) => {
                                          e.stopPropagation();

                                          await updateVariantField(
                                            index,
                                            "right_image",
                                            null
                                          );
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <p className="click-to-upload">
                                  Click to upload
                                </p>
                              )}
                              <input
                                type="file"
                                accept=".png, .jpg, .jpeg, .webp"
                                name="right_image"
                                key={`${index}_right_image`}
                                id={`${index}_right_image`}
                                onChange={async (e) => {
                                  await updateVariantField(
                                    index,
                                    "right_image",
                                    e.target.files[0]
                                  );
                                  await updateVariantField(
                                    index,
                                    "right_image_error",
                                    ""
                                  );
                                }}
                                style={{ opacity: 0 }}
                              />
                            </div>
                            {variantProduct.right_image_error && (
                              <label className="red">
                                {variantProduct.right_image_error}
                              </label>
                            )}
                          </div>
                          <div>
                            <label>
                              Back View{" "}
                              {product.status === "Publish" && (
                                <span className="red">*</span>
                              )}
                            </label>
                            <div
                              onClick={() => {
                                handleImageUpload(
                                  `${index}_back_image`,
                                  "back_image",
                                  "back_image_error",
                                  index,
                                  variantProduct.stock_id
                                );
                              }}
                              className={
                                "image-dropzone " +
                                (variantProduct.back_image &&
                                  "dropzone-uploaded")
                              }
                            >
                              {variantProduct.back_image ? (
                                <>
                                  <img
                                    className="variantProdImgs product-image"
                                    src={
                                      variantProduct.back_image instanceof File
                                        ? URL.createObjectURL(
                                            variantProduct.back_image
                                          )
                                        : `${API_HOST}/${variantProduct.back_image}`
                                    }
                                    loading="lazy"
                                  />
                                  <div className="img-btn-action">
                                    <div className="img-btns">
                                      {isBase64Image(
                                        variantProduct.back_image
                                      ) && (
                                        <button
                                          type="button"
                                          className="image-edit-icon"
                                          onClick={() => {
                                            loadImgOnModal(
                                              "back_image",
                                              variantProduct.back_image
                                            );
                                            setProdImageToEdit((prev) => ({
                                              ...prev,
                                              imgFile:
                                                variantProduct.back_image,
                                              imgName: "back_image",
                                              imgNameError:
                                                variantProduct.back_image_error,
                                              index: index,
                                            }));
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faEdit} />
                                          <span>Edit</span>
                                        </button>
                                      )}
                                      <button
                                        type="button"
                                        className="image-remove-icon"
                                        onClick={async (e) => {
                                          e.stopPropagation();
                                          await updateVariantField(
                                            index,
                                            "back_image",
                                            null
                                          );
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <p className="click-to-upload">
                                  Click to upload
                                </p>
                              )}
                              <input
                                type="file"
                                accept=".png, .jpg, .jpeg, .webp"
                                name="back_image"
                                key={`${index}_back_image`}
                                id={`${index}_back_image`}
                                onChange={async (e) => {
                                  await updateVariantField(
                                    index,
                                    "back_image",
                                    e.target.files[0]
                                  );
                                  await updateVariantField(
                                    index,
                                    "back_image_error",
                                    ""
                                  );
                                }}
                                style={{ opacity: 0 }}
                              />
                            </div>
                            {variantProduct.back_image_error && (
                              <label className="red">
                                {variantProduct.back_image_error}
                              </label>
                            )}
                          </div>
                          <div>
                            <label>
                              Top View{" "}
                              {product.status === "Publish" && (
                                <span className="red">*</span>
                              )}
                            </label>
                            <div
                              onClick={() => {
                                handleImageUpload(
                                  `${index}_top_image`,
                                  "top_image",
                                  "top_image_error",
                                  index,
                                  variantProduct.stock_id
                                );
                              }}
                              className={
                                "image-dropzone " +
                                (variantProduct.top_image &&
                                  "dropzone-uploaded")
                              }
                            >
                              {variantProduct.top_image ? (
                                <>
                                  <img
                                    className="variantProdImgs product-image"
                                    // src={variantProduct.top_image}
                                    src={
                                      variantProduct.top_image instanceof File
                                        ? URL.createObjectURL(
                                            variantProduct.top_image
                                          )
                                        : `${API_HOST}/${variantProduct.top_image}`
                                    }
                                    loading="lazy"
                                  />
                                  <div className="img-btn-action">
                                    <div className="img-btns">
                                      {isBase64Image(
                                        variantProduct.top_image
                                      ) && (
                                        <button
                                          type="button"
                                          className="image-edit-icon"
                                          onClick={() => {
                                            loadImgOnModal(
                                              "top_image",
                                              variantProduct.top_image
                                            );
                                            setProdImageToEdit((prev) => ({
                                              ...prev,
                                              imgFile: variantProduct.top_image,
                                              imgName: "top_image",
                                              imgNameError:
                                                variantProduct.top_image_error,
                                              index: index,
                                            }));
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faEdit} />
                                          <span>Edit</span>
                                        </button>
                                      )}
                                      <button
                                        type="button"
                                        className="image-remove-icon"
                                        onClick={async (e) => {
                                          e.stopPropagation();
                                          await updateVariantField(
                                            index,
                                            "top_image",
                                            null
                                          );
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <p className="click-to-upload">
                                  Click to upload
                                </p>
                              )}
                              <input
                                type="file"
                                accept=".png, .jpg, .jpeg, .webp"
                                name="top_image"
                                key={`${index}_top_image`}
                                id={`${index}_top_image`}
                                onChange={async (e) => {
                                  await updateVariantField(
                                    index,
                                    "top_image",
                                    e.target.files[0]
                                  );
                                  await updateVariantField(
                                    index,
                                    "top_image_error",
                                    ""
                                  );
                                }}
                                style={{ opacity: 0 }}
                              />
                            </div>
                            {variantProduct.top_image_error && (
                              <label className="red">
                                {variantProduct.top_image_error}
                              </label>
                            )}
                          </div>
                        </div>
                        <div className="video-url">
                          <label className="mt-10">Video URL</label>
                          <input
                            type="text"
                            name="video_url"
                            value={variantProduct.video_url}
                            onChange={async (e) => {
                              let value = e.target.value;
                              await updateVariantField(
                                index,
                                "video_url",
                                value
                              );
                              await updateVariantField(
                                index,
                                "video_url_error",
                                ""
                              );
                              if (page == "edit") {
                                await updateVariantField(
                                  index,
                                  variantProduct.stock_id == null
                                    ? "new_stock"
                                    : "update_stock",
                                  true
                                );
                              }
                            }}
                            className="form-control"
                          />
                          {variantProduct.video_url_error && (
                            <label className="red">
                              {variantProduct.video_url_error}
                            </label>
                          )}
                        </div>
                      </div>

                      {/* Product Price, Estimated Original Price, Warehouse */}
                      <div className="small-box">
                        <div className="multi-input-row">
                          <div>
                            <label>
                              Product Price (₹){" "}
                              {product.status === "Publish" && (
                                <span className="red">*</span>
                              )}
                            </label>
                            <input
                              type="number"
                              readOnly={variantProduct.product_price_as_first}
                              name="product_price"
                              min="100"
                              value={variantProduct.product_price}
                              onKeyDown={(e) => {
                                preventEvent(e);
                              }}
                              onChange={async(e) => {
                                let value = e.target.value;
                                if (value > 100000) {
                                  await updateVariantField(
                                    index,
                                    "product_price_error",
                                    "Product price must not be greater than 100,000."
                                  );
                                } else {
                                  if (index === 0) {
                                    variantsProductList &&
                                      variantsProductList.forEach(
                                        async (prod, index) => {
                                          if (
                                            index === 0 ||
                                            prod.product_price_as_first
                                          ) {
                                            await updateVariantField(
                                              index,
                                              "product_price",
                                              value
                                            );
                                            await updateVariantField(
                                              index,
                                              "product_price_error",
                                              ""
                                            );
                                          }
                                        }
                                      );
                                  } else {
                                    await updateVariantField(
                                      index,
                                      "product_price",
                                      value
                                    );
                                  }
                                  await updateVariantField(
                                    index,
                                    "product_price_error",
                                    ""
                                  );
                                }

                                if (page == "edit") {
                                  await updateVariantField(
                                    index,
                                    variantProduct.stock_id == null
                                      ? "new_stock"
                                      : "update_stock",
                                    true
                                  );
                                }
                              }}
                              className="form-control"
                            />
                            {variantProduct.product_price_error && (
                              <label className="red">
                                {variantProduct.product_price_error}
                              </label>
                            )}
                          </div>
                          <div>
                            <label>
                              Estimated Original Price (₹){" "}
                              {product.status === "Publish" && (
                                <span className="red">*</span>
                              )}
                            </label>
                            <input
                              type="number"
                              name="current_market_price"
                              readOnly={product.current_market_price_as_first}
                              value={variantProduct.current_market_price}
                              min="200"
                              onKeyDown={(e) => {
                                preventEvent(e);
                              }}
                              onChange={async(e) => {
                                let value = e.target.value;
                                if (value > 200000) {
                                  await updateVariantField(
                                    index,
                                    "current_market_price_error",
                                    "Estimated Original price must not be greater than 200,000."
                                  );
                                } else {
                                  if (index === 0) {
                                    variantsProductList &&
                                      variantsProductList.forEach(
                                        async (prod, index) => {
                                          if (
                                            index === 0 ||
                                            prod.current_market_price_as_first
                                          ) {
                                            await updateVariantField(
                                              index,
                                              "current_market_price",
                                              value
                                            );
                                            await updateVariantField(
                                              index,
                                              "current_market_price_error",
                                              ""
                                            );
                                          }
                                        }
                                      );
                                  } else {
                                    await updateVariantField(
                                      index,
                                      "current_market_price",
                                      value
                                    );
                                  }
                                  await updateVariantField(
                                    index,
                                    "current_market_price_error",
                                    ""
                                  );
                                }
                                if (page == "edit") {
                                  await updateVariantField(
                                    index,
                                    variantProduct.stock_id == null
                                      ? "new_stock"
                                      : "update_stock",
                                    true
                                  );
                                }
                              }}
                              className="form-control"
                            />
                            {variantProduct.current_market_price_error && (
                              <label className="red">
                                {variantProduct.current_market_price_error}
                              </label>
                            )}
                          </div>
                          <div>
                            <label>Selling Price (₹)</label>
                            <div className="selling-price-line">
                              <input
                                disabled={true}
                                readOnly={true}
                                type="number"
                                name="selling_price"
                                value={variantProduct.selling_price}
                                onChange={handleInputChange}
                                className="form-control"
                                style={{ backgroundColor: "#e7e7e7" }}
                              />
                              <button
                                type="button"
                                className={`btn ${
                                  disableCalculate ? "" : "secondary-btn"
                                }`}
                                disabled={disableCalculate}
                                style={{
                                  cursor: disableCalculate && "not-allowed",
                                  opacity: disableCalculate && "0.5",
                                  backgroundColor:
                                    disableCalculate && "#E7E7E7",
                                }}
                                onClick={(e) => {
                                  calculateSellingPrice( index,
                                    variantProduct.current_market_price,
                                    variantProduct.product_price);
                                }}
                              >
                                Calculate
                              </button>
                            </div>
                            {variantProduct.selling_price_error && (
                              <label className="red">
                                {variantProduct.selling_price_error}
                              </label>
                            )}
                          </div>
                          <div>
                            <label>
                              Quantity
                              {product.status === "Publish" &&
                                variantsProductList.length > 1 && (
                                  <span className="red">*</span>
                                )}
                            </label>
                            <input
                              type="number"
                              name="quantity"
                              min={0}
                              className="form-control"
                              value={variantProduct.quantity}
                              onKeyDown={(e) => {
                                preventEvent(e);
                              }}
                              onChange={async(e) => {
                                let value = e.target.value;
                                await updateVariantField(
                                  index,
                                  "quantity",
                                  value
                                );
                                await updateVariantField(
                                  index,
                                  "quantity_error",
                                  ""
                                );

                                if (page == "edit") {
                                  await updateVariantField(
                                    index,
                                    variantProduct.stock_id == null
                                      ? "new_stock"
                                      : "update_stock",
                                    true
                                  );
                                }
                              }}
                            />
                            {variantProduct.quantity_error && (
                              <label className="red">
                                {variantProduct.quantity_error}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* SKU, Barcode, HSN */}
                      <div className="small-box">
                        <div className="multi-input-row">
                          <div>
                            <label>SKU</label>
                            <input
                              type="text"
                              name="sku"
                              className="form-control"
                              value={variantProduct.sku}
                              onChange={async (e) => {
                                let value = e.target.value;
                                await updateVariantField(index, "sku", value);
                                await updateVariantField(
                                  index,
                                  "sku_error",
                                  ""
                                );

                                if (page == "edit") {
                                  await updateVariantField(
                                    index,
                                    variantProduct.stock_id == null
                                      ? "new_stock"
                                      : "update_stock",
                                    true
                                  );
                                }
                              }}
                            />
                            {variantProduct.sku_error && (
                              <label className="red">
                                {variantProduct.sku_error}
                              </label>
                            )}
                          </div>
                          <div>
                            <label>Barcode</label>
                            <input
                              type="text"
                              name="barcode"
                              className="form-control"
                              value={variantProduct.barcode}
                              onChange={async (e) => {
                                let value = e.target.value;
                                await updateVariantField(
                                  index,
                                  "barcode",
                                  value
                                );
                                await updateVariantField(
                                  index,
                                  "barcode_error",
                                  ""
                                );
                                if (page == "edit") {
                                  await updateVariantField(
                                    index,
                                    variantProduct.stock_id == null
                                      ? "new_stock"
                                      : "update_stock",
                                    true
                                  );
                                }
                              }}
                            />
                            {variantProduct.barcode_error && (
                              <label className="red">
                                {variantProduct.barcode_error}
                              </label>
                            )}
                          </div>

                          <div className="">
                            <div
                              style={{
                                position: "relative",
                              }}
                              ref={
                                variantProduct.variantListShow
                                  ? dropdownContainerRef
                                  : null
                              }
                            >
                              <label>
                                Variant{" "}
                                {product.status === "Publish" && variantsProductList.length > 1 && (
                                  <span className="red">*</span>
                                )}
                              </label>

                              <div
                                className="form-control"
                                style={{
                                  display: "flex",
                                }}
                              >
                                <span
                                  className="varientDropDownTitle"
                                  onClick={() => {
                                    changeVariantDropDownStatus(
                                      index,
                                      "variantListShow",
                                      variantProduct.variantListShow
                                    );
                                  }}
                                >
                                  {" "}
                                  <p>
                                    {variantProduct.selectedVariant &&
                                    variantProduct.selectedVariant.id
                                      ? variantProduct.selectedVariant.name
                                      : "Select"}
                                  </p>{" "}
                                  <FontAwesomeIcon
                                    icon={
                                      variantProduct.variantListShow
                                        ? faSortUp
                                        : faSortDown
                                    }
                                  />
                                </span>
                              </div>
                              {variantProduct.variantListShow && (
                                <div className="variantDropdownList">
                                  {variantsList.map((variant) => {
                                    return (
                                      <div
                                        className="variantsListOptions"
                                        ref={
                                          variantProduct.variantListShow
                                            ? dropdownRef
                                            : null
                                        }
                                        onClick={async () => {
                                          changeVariantDropDownStatus(
                                            index,
                                            "variantListShow",
                                            variantProduct.variantListShow
                                          );
                                          await updateVariantField(
                                            index,
                                            "variantListVisible",
                                            false
                                          );
                                          await updateVariantField(
                                            index,
                                            "selectedVariant",
                                            variant
                                          );
                                          await updateVariantField(
                                            index,
                                            "variant_values",
                                            []
                                          );
                                          await updateVariantField(
                                            index,
                                            "selectedVariant_error",
                                            ""
                                          );
                                          if (
                                            variant.variant_values &&
                                            variant.variant_values.length > 0
                                          ) {
                                            await updateVariantField(
                                              index,
                                              "selectedVariant",
                                              variant
                                            );
                                          }

                                          if (page == "edit") {
                                            await updateVariantField(
                                              index,
                                              variantProduct.stock_id == null
                                                ? "new_stock"
                                                : "update_stock",
                                              true
                                            );
                                          }
                                        }}
                                      >
                                        <input
                                          type="radio"
                                          checked={
                                            variantProduct.selectedVariant &&
                                            variantProduct.selectedVariant.id &&
                                            variantProduct.selectedVariant.id ===
                                              variant.id
                                          }
                                        />
                                        <label htmlFor="">{variant.name}</label>
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                              {variantProduct.selectedVariant_error && (
                                <label className="red">
                                  {variantProduct.selectedVariant_error}
                                </label>
                              )}
                            </div>
                          </div>
                          <div className="">
                            <div
                              style={{
                                position: "relative",
                              }}
                              ref={
                                variantProduct.variantValuesListShow
                                  ? dropdownContainerRef
                                  : null
                              }
                            >
                              <label>
                                Variant Value{" "}
                                {product.status === "Publish" && variantsProductList.length > 1 && (
                                  <span className="red">*</span>
                                )}
                              </label>
                              <div
                                className="form-control"
                                style={{
                                  display: "flex",
                                }}
                              >
                                <span
                                  className="varientDropDownTitle"
                                  onClick={() => {
                                    changeVariantDropDownStatus(
                                      index,
                                      "variantValuesListShow",
                                      variantProduct.variantValuesListShow
                                    );
                                  }}
                                >
                                  {" "}
                                  <p>
                                    {variantProduct.variant_values &&
                                    variantProduct.variant_values.value
                                      ? variantProduct.variant_values.value
                                      : "Select"}
                                  </p>{" "}
                                  <FontAwesomeIcon
                                    icon={
                                      variantProduct.variantValuesListShow
                                        ? faSortUp
                                        : faSortDown
                                    }
                                  />
                                </span>
                              </div>
                              {variantProduct.variantValuesListShow && (
                                <div
                                  className="variantDropdownList"
                                  ref={
                                    variantProduct.variantValuesListShow
                                      ? dropdownRef
                                      : null
                                  }
                                >
                                  {variantProduct.selectedVariant ? (
                                    <>
                                      {variantProduct.selectedVariant
                                        .variant_values &&
                                        variantProduct.selectedVariant
                                          .variant_values.length > 0 && (
                                          <>
                                            {variantProduct.selectedVariant.variant_values.map(
                                              (variantValue) => {
                                                return (
                                                  <div
                                                    className="variantsListOptions"
                                                    onClick={async () => {
                                                      changeVariantDropDownStatus(
                                                        index,
                                                        "variantValuesListShow",
                                                        variantProduct.variantValuesListShow
                                                      );
                                                      await updateVariantField(
                                                        index,
                                                        "variant_values_error",
                                                        ""
                                                      );
                                                      await updateVariantField(
                                                        index,
                                                        "variant_values",
                                                        variantValue
                                                      );

                                                      await updateVariantField(
                                                        index,
                                                        "variantValuesListVisible",
                                                        false
                                                      );

                                                      checkVariantExist(
                                                        index,
                                                        variantValue
                                                      );
                                                    }}
                                                  >
                                                    <input
                                                      type="radio"
                                                      checked={
                                                        variantProduct.variant_values &&
                                                        variantProduct
                                                          .variant_values.id ===
                                                          variantValue.id
                                                      }
                                                    />
                                                    <label htmlFor="">
                                                      {variantValue.value}
                                                    </label>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </>
                                        )}
                                    </>
                                  ) : (
                                    <label>Please select a variant</label>
                                  )}
                                </div>
                              )}

                              {variantProduct.variant_values_error && (
                                <label className="red">
                                  {variantProduct.variant_values_error}
                                </label>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {(page === "new" || page === "edit") && (
                <button
                  className="addRemoveProdVariantBtn"
                  onClick={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    await addNewVariant();
                    if (variantsProductList.length == 1) {
                      await updateVariantField(0, "is_default", 1);
                    }
                  }}
                  id={variantsProductList.length === 0 && "addProductBtn"}
                  style={{
                    position: "ab",
                  }}
                >
                  {variantsProductList.length === 0
                    ? "+ Add Product"
                    : "+ Add Variant"}
                </button>
              )}
            </div>
            {/* ====================== map in variant list End ========================= */}

            {/* GST, Platform Charge Type and Value */}
            <div className="small-box">
              <div className="multi-input-row">
                <div>
                  <label>
                    GST Percentage{" "}
                    {product.status === "Publish" && (
                      <span className="red">*</span>
                    )}
                  </label>
                  <select
                    value={product.gst}
                    name="gst"
                    onChange={handleInputChange}
                    className="form-control gst-percentage"
                  >
                    <option value="">Select</option>
                    {Object.entries(gstPercentage).map(([key, value]) => (
                      <option key={key} value={value}>
                        {`${value}%`}
                      </option>
                    ))}
                  </select>
                  {errors.gst && <label className="red">{errors.gst}</label>}
                </div>
                <div>
                  <label>
                    Platform Charges{" "}
                    {product.status === "Publish" && (
                      <span className="red">*</span>
                    )}
                  </label>
                  <div className="platform-charge-area">
                    <select
                      value={product.platform_charge_type}
                      onChange={handleInputChange}
                      name="platform_charge_type"
                      className="form-control platform-charge-type"
                    >
                      <option value="Percentage">Percentage</option>
                      <option value="Flat">Flat</option>
                    </select>
                    <input
                      value={product.platform_charge}
                      onChange={handleInputChange}
                      name="platform_charge"
                      type="text"
                      className="form-control platform-charge"
                    />
                  </div>
                  {errors.platform_charge && (
                    <label className="red">{errors.platform_charge}</label>
                  )}
                </div>
                <div>
                  <label>
                    Warehouse{" "}
                    {product.status === "Publish" && (
                      <span className="red">*</span>
                    )}
                  </label>
                  <select
                    className="form-control warehouse-select"
                    name="warehouse_id"
                    onChange={handleInputChange}
                    value={product.warehouse_id}
                  >
                    <option value="">Select</option>
                    {warehouseOptionsValue.map((option) => {
                      return (
                        <option key={option.id} value={option.id}>
                          {option.warehouse_name}
                        </option>
                      );
                    })}
                  </select>
                  {errors.warehouse_id && (
                    <label className="red">{product.warehouse_id}</label>
                  )}
                </div>
                <div>
                  <label>HSN</label>
                  <input
                    type="text"
                    name="hsn"
                    className="form-control"
                    value={product.hsn}
                    onChange={handleInputChange}
                  />
                  {errors.hsn && <label className="red">{product.hsn}</label>}
                </div>
              </div>
            </div>
            {/* Length, Width, Height, Weight */}
            <div className="small-box">
              <div className="multi-input-row">
                <div>
                  <label>
                    Box Length (cm){" "}
                    {product.status === "Publish" && (
                      <span className="red">*</span>
                    )}
                  </label>
                  <input
                    type="text"
                    name="length"
                    value={product.length}
                    onChange={handleInputChange}
                    className="form-control"
                    step="0.01"
                    onKeyPress={handleKeyPress}
                  />
                  {errors.length && (
                    <label className="red">{errors.length}</label>
                  )}
                </div>
                <div>
                  <label>
                    Box width (cm){" "}
                    {product.status === "Publish" && (
                      <span className="red">*</span>
                    )}
                  </label>
                  <input
                    type="text"
                    name="width"
                    value={product.width}
                    onChange={handleInputChange}
                    className="form-control"
                    step="0.01"
                    onKeyPress={handleKeyPress}
                  />
                  {errors.width && (
                    <label className="red">{errors.width}</label>
                  )}
                </div>
                <div>
                  <label>
                    Box height (cm){" "}
                    {product.status === "Publish" && (
                      <span className="red">*</span>
                    )}
                  </label>
                  <input
                    type="text"
                    name="height"
                    value={product.height}
                    onChange={handleInputChange}
                    className="form-control"
                    onKeyPress={handleKeyPress}
                  />
                  {errors.height && (
                    <label className="red">{errors.height}</label>
                  )}
                </div>
                <div>
                  <label>
                    Weight (kg){" "}
                    {product.status === "Publish" && (
                      <span className="red">*</span>
                    )}
                  </label>
                  <input
                    type="text"
                    name="weight"
                    value={product.weight}
                    onChange={handleInputChange}
                    className="form-control"
                    step="0.01"
                    onKeyPress={handleKeyPress}
                  />
                  {errors.weight && (
                    <label className="red">{errors.weight}</label>
                  )}
                </div>
              </div>
            </div>

            {/* Product SEO */}
            <div className="small-box">
              <div className="small-container-heading">
                <p className="productShippingPara">Search Engine Listing</p>
                <button
                  className="blue-edit-btn"
                  type="button"
                  onClick={() => {
                    setOpenSeoEdit(!openSeoEdit);
                  }}
                >
                  {openSeoEdit ? "Close" : "Edit"}
                </button>
              </div>

              <div className="seo-read">
                <p className="seo-read-title">
                  {product.meta_title
                    ? product.meta_title
                    : product.name &&
                      product.name.substring(0, metaTitleMaxLength)}
                </p>
                <p className="seo-read-url">
                  {BUYER_APP}/products/
                  {getSlugURL(product.name)}...
                </p>
                <p className="seo-read-description">
                  {product.meta_description
                    ? product.meta_description
                    : product.description &&
                      removeHtmlTags(product.description).substring(
                        0,
                        metaDescMaxLength
                      )}
                </p>
              </div>

              <div
                className={`product-seo-input ${openSeoEdit ? "" : "close"}`}
              >
                <div className="single-row">
                  <label className="meta-label">
                    <span>Meta Title</span>
                    <span>
                      {metaTitleRemaining}/{metaTitleMaxLength}
                    </span>
                  </label>
                  <input
                    value={product.meta_title}
                    onChange={(e) => {
                      e.preventDefault();
                      handleInputChange(e);
                      const length = e.target.value.length;
                      setMetaTitleRemaining(metaTitleMaxLength - length);
                    }}
                    className="form-control"
                    type="text"
                    name="meta_title"
                    minLength={3}
                    maxLength={metaTitleMaxLength}
                  />
                </div>
                <div className="single-row">
                  <label className="meta-label">
                    <span>Meta Description</span>
                    <span>
                      {metaDescRemaining}/{metaDescMaxLength}
                    </span>
                  </label>
                  <textarea
                    value={product.meta_description}
                    name="meta_description"
                    className="form-control meta-description-input"
                    onChange={(e) => {
                      e.preventDefault();
                      handleInputChange(e);
                      const length = e.target.value.length;
                      setMetaDescRemaining(metaDescMaxLength - length);
                    }}
                    minLength={3}
                    maxLength={metaDescMaxLength}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div className="right-area">
            <div className="small-box">
              <label>
                Status <span className="red">*</span>
              </label>
              <select
                name="status"
                className="form-control"
                value={product.status}
                onChange={(e) => {
                  const val = e.target.value;
                  setProduct((prev) => ({
                    ...prev,
                    status: val,
                  }));
                }}
              >
                <option value="Publish">Publish</option>
                <option value="Draft">Draft</option>
              </select>
            </div>
            <div className="small-box">
              <label>
                Category{" "}
                {product.status === "Publish" && <span className="red">*</span>}
              </label>
              <select
                className="form-control"
                name="category_id"
                value={product.category_id}
                onChange={handleInputChange}
              >
                <option value="">Select</option>
                {categoryData &&
                  categoryData.map((category, index) => (
                    <option key={index} value={category.id}>
                      {category.name}
                    </option>
                  ))}
              </select>
              {errors.category_id && (
                <label className="red">{errors.category_id}</label>
              )}
              <label className="mt-10">Brand</label>
              <select
                className="form-control"
                name="brand_id"
                value={product.brand_id}
                onChange={handleInputChange}
              >
                <option value="">Select</option>
                {brandData &&
                  brandData.map((brands, index) => (
                    <option key={index} value={brands.id}>
                      {brands.name}
                    </option>
                  ))}
              </select>
              {errors.brand_id && (
                <label className="red">{errors.brand_id}</label>
              )}
              <label className="mt-10">
                Condition{" "}
                {product.status === "Publish" && <span className="red">*</span>}
              </label>
              <select
                name="condition"
                value={product.condition}
                onChange={handleInputChange}
                className="form-control"
              >
                <option value="">Select</option>
                {conditionData &&
                  conditionData.map((conditions) => (
                    <option key={conditions} value={conditions}>
                      {conditions}
                    </option>
                  ))}
              </select>
              {errors.condition && (
                <label className="red">{errors.condition}</label>
              )}
              <div>
                <label className="mt-10">
                  Applicable Age Group{" "}
                  {product.status === "Publish" && (
                    <span className="red">*</span>
                  )}
                </label>
                <select
                  className="form-control"
                  name="age_group_id"
                  value={product.age_group_id}
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  {applicableAgeGroupData.map((ageGroup) => (
                    <option key={ageGroup.id} value={ageGroup.id}>
                      {ageGroup.title}
                    </option>
                  ))}
                </select>
                {errors.age_group_id && (
                  <label className="red">{errors.age_group_id}</label>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Save Product */}
        <div className="product-btn-area">
          <button
            type="button"
            onClick={() => {
              window.history.back();
            }}
            className="secondary-btn"
          >
            Cancel
          </button>

          <button
            type="button"
            className="primary-btn"
            style={{
              opacity: enableAddProductBtn ? 1 : 0.5,
            }}
            onClick={() => {
              if (!loadingButton && enableAddProductBtn) {
                handleButtonSubmit();
              }
            }}
          >
            {loadingButton ? <LoaderButton /> : "Save"}
          </button>
        </div>
      </div>

      {/* Max Selling Price */}
      {infoModalOpen && (
        <div
          className="modal fade"
          style={{ display: infoModalOpen ? "block" : "none" }}
        >
          <div className="modal-content">
            <h3 className="modal-header">Oops!</h3>
            <p className="modal-line">{modalMessage}</p>
            <div className="modal-buttons">
              <button
                className="btn primary-btn"
                onClick={() => {
                  setInfoModalOpen(false);
                }}
              >
                Understood
              </button>
            </div>
          </div>
        </div>
      )}

      {/* image upload modal */}
      {showImgUpModal && (
        <div className="modal fade">
          <div className="img-modal-content">
            <div className="img-modal-header">
              <p>Image upload</p>
              <button
                className="modal-close-icon"
                onClick={() => {
                  setShowImgUpModal(false);
                }}
              >
                <FontAwesomeIcon icon={faXmark} />
              </button>
            </div>
            <div className="modal-body">
              <div className="modal-img-editor">
                <Cropper
                  src={rawImage}
                  ref={cropperRef}
                  initialAspectRatio={1}
                  aspectRatio={rawImgName === "thumb_image" ? 1 : undefined}
                  preview=".img-preview"
                  viewMode={1}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false}
                  guides={true}
                  style={{ maxHeight: 350 }}
                />
                <div
                  className="img-preview"
                  style={{
                    width: "100%",
                    float: "left",
                    height: "300px",
                    border: "1px solid #ced4da",
                    maxHeight: 350,
                  }}
                />
              </div>
            </div>
            <div className="modal-buttons">
              <button
                className="btn secondary-btn"
                onClick={() => {
                  setShowImgUpModal(false);
                }}
              >
                Cancel
              </button>
              <button className="btn primary-btn" onClick={cropImage}>
                Upload
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InHouseProduct;
