import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoaderButton from "../../../Components/LoaderButton";
import Paginate from "../../../Components/Pagination/Paginate";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { useContext, useEffect, useMemo, useState } from "react";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";
import { useLocation, useNavigate } from "react-router-dom";
import InputContext from "../../../Components/InputContext";
import APIEndpoint from "../../../config/APIEndpoints.json";
import stringConstant from "../../../config/ProjectConstants.json";
import Navigation from "../../../config/Navigation.json";
import Toast from "../../../Components/Toast";
const API_HOST = process.env.REACT_APP_HOST_API_URL;

const VariantList = () => {
  const location = useLocation();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  let [currentPage, setCurrentPage] = useState("");
  let [lastPage, setLastPage] = useState("");
  const [pagination, setPagination] = useState("");
  const [varientlist, setVarientlist] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const variantPerPage = 10;
  const [loading, setLoading] = useState(true);
  let [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const contextObj = useContext(InputContext);
  const [totalCount, setTotalCount] = useState("");
  const [addVariant, showAddVariant] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [isSearchApplied, setIsSearchApplied] = useState(false);
  const [variant, setVariant] = useState({
    name: "",
  });
  const [variantEdit, setEditVariant] = useState({
    name: "",
  });
  const [editId, setEditId] = useState(null);
  const [deleteBtnLoading, setDeleteBtnLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const token = getDataFromCookies("7b9KpQ3rW6");
  const [vNameError, setVNameError] = useState("");
  const [editVariant, showEditVariant] = useState(false);
  const [EditNameError, setEditNameError] = useState("");
  const handleURLChange = (data) => {
    window.scrollTo(0, 0);
    CheckGetVarientList(data);
  };

  const { showToast } = Toast();

  const CheckGetVarientList = async (url) => {
    try {
      let token = getDataFromCookies("7b9KpQ3rW6");
      if (searchQuery !== "") {
        url = url + "&search=" + searchQuery;
      }
      if (!token) {
        contextObj.setInput("prevPath", location.pathname);
        navigate(`${Navigation.login}`);
        return;
      }
      const response = await fetch(`${url}&per_page=${variantPerPage}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const res = await response.json();
      if (res.status === 401 && res.message === "You are not authorized.") {
        contextObj.setInput("prevPath", location.pathname);
        removeDataFromCookie("7b9KpQ3rW6", "/");
        removeDataFromCookie("loggedIn", "/");
        contextObj.setInput("navigation", "true");
        contextObj.setInput("navigationFrom", "sessionExpired");
        navigate(Navigation.login);
        showToast("info", "Sessions expired!");
      } else if (res.status === 401 && res.message !== "") {
        showToast("error", res.message);
      } else if (res.status === 404) {
        setVarientlist([]);
        setPagination("");
      } else if (res.status === 200) {
        setVarientlist(res.data.data);
        setPagination(res.data);
        setCurrentPage(res.data.current_page);
        setLastPage(res.data.last_page);
        setTotalCount(res.data.total);
      }
      setSearchLoading(false);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    CheckGetVarientList(`${API_HOST}/${APIEndpoint.CrudVariant}?page_no=1`);
  }, []);

  useEffect(() => {
    if (isSearchApplied) {
      setSearchLoading(true);
      const debounceTimeout = setTimeout(() => {
        setLoading(true);
        currentPage = 1;
        CheckGetVarientList(
          `${API_HOST}/${APIEndpoint.CrudVariant}?page_no=${currentPage}`
        );
      }, 1000);
      return () => {
        clearTimeout(debounceTimeout);
      };
    }
  }, [searchQuery]);
  // Add Variant operations

  const handleClick = (id, name) => {
    setSelectedVariant({ id, name });
    // Navigate to the variant value page if needed
    navigate(`/variant-value/${id}`);
  };

  const handelAddVariant = () => {
    showAddVariant(true);
  };

  const closeAddVariant = () => {
    showAddVariant(false);
    setVNameError("");
    setVariant((prevData) => ({
      ...prevData,
      name: "",
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setVNameError("");
    if (name === "name") {
      setVariant((prevData) => ({
        ...prevData,
        [name]: value.trimStart(),
      }));
    }
  };

  const AddVarientData = async () => {
    let error = false;
    if (variant.name === "" || variant.name === null) {
      setVNameError("This field is required");
      error = true;
    }

    if (error) {
      setLoadingButton(false);
      return;
    }

    await fetch(`${API_HOST}/${APIEndpoint.addVarient}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(variant),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setLoadingButton(false);
        if (data.status === 201) {
          showToast(
            "success",
            stringConstant.alertErrorMessage.alertForVariantCreated
          );
          closeAddVariant();
          CheckGetVarientList(
            `${API_HOST}/${APIEndpoint.CrudVariant}?page_no=1`
          );
        } else if (data.status === 400) {
          if (data.errors.name) {
            setVNameError(data.errors.name[0]);
          }
        } else if (
          data.status === 401 &&
          data.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (data.status === 401 && data.message !== "") {
          showToast("error", data.message);
        } else {
          console.error("Data is not an array");
        }
      })
      .catch((err) => console.error(err));
  };

  // Delete Varient Calls

  const openDeleteModal = (id) => {
    setDeleteId(id);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setDeleteId(null);
    setShowDeleteModal(false);
  };

  // Delete Varient API Calls

  const handleConfirmDelete = async (id) => {
    try {
      if (!token) {
        contextObj.setInput("prevPath", location.pathname);
        navigate(`${Navigation.login}`);
        return;
      }

      setDeleteBtnLoading(true);
      const response = await fetch(
        `${API_HOST}/${APIEndpoint.CrudVariant}/${id}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        showToast("success", "Variant deleted successfully");
        // Assuming this function retrieves updated FAQ data
        if (currentPage === lastPage) {
          currentPage =
            totalCount % variantPerPage === 1 ? currentPage - 1 : currentPage;
          CheckGetVarientList(
            `${API_HOST}/${APIEndpoint.CrudVariant}?page_no=${currentPage}`
          );
        } else {
          CheckGetVarientList(
            `${API_HOST}/${APIEndpoint.CrudVariant}?page_no=${currentPage}`
          );
        }
      } else if (response.status === 401) {
        navigate(Navigation.login);
        removeDataFromCookie("7b9KpQ3rW6", "/");
        removeDataFromCookie("loggedIn", "/");
        showToast("info", "Session expired!");
      } else if (response.status === 401 && response.message !== "") {
        showToast("error", response.message);
      } else {
        showToast("info", response.message || "Something went wrong");
      }
    } catch (error) {
      console.error("Error deleting FAQ:", error);
      showToast("error", "An error occurred while deleting FAQ.");
    } finally {
      setDeleteBtnLoading(false);
      closeDeleteModal();
    }
  };

  // Edit Variant Operation
  const varientEdit = (id, name) => {
    showEditVariant(true);

    setEditId(id);
    setEditVariant((prevData) => ({
      ...prevData,
      name: name,
    }));
  };

  const closeEditVariant = () => {
    showEditVariant(false);
    setEditNameError("");
    setEditVariant((prevData) => ({
      ...prevData,
      name: "",
    }));
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditNameError("");
    if (name === "name") {
      setEditVariant((prevData) => ({
        ...prevData,
        [name]: value.trimStart(),
      }));
    }
  };

  const EditVarientData = async () => {
    let error = false;
    if (variantEdit.name === "" || variantEdit.name === null) {
      setEditNameError("This field is required");
      error = true;
    }

    if (error) {
      setLoadingButton(false);
      return;
    }

    await fetch(`${API_HOST}/${APIEndpoint.CrudVariant}/${editId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(variantEdit),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setLoadingButton(false);
        if (data.status === 200) {
          showToast(
            "success",
            stringConstant.alertErrorMessage.alertforVariantUpdate
          );
          closeEditVariant();

          CheckGetVarientList(
            `${API_HOST}/${APIEndpoint.CrudVariant}?page_no=1`
          );
        } else if (data.status === 400) {
          if (data.message) {
            setEditNameError(data.message);
          } else if (data.errors.name) {
            setEditNameError(data.errors.name[0]);
          }
        } else if (
          data.status === 401 &&
          data.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (data.status === 401 && data.message !== "") {
          showToast("error", data.message);
        } else {
          console.error("Data is not an array");
        }
      })
      .catch((err) => console.error(err));
  };

  // Table Logic
  const columns = useMemo(
    () => [
      {
        Header: "Serial Number",
        accessor: (row, index) =>
          index + 1 + (currentPage - 1) * variantPerPage,
      },
      {
        Header: "Variant Name",
        accessor: "name",
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }) => {
          const id = row.original.id;
          const name = row.original.name;

          return (
            <div>
              <button
                onClick={() => varientEdit(id, name)}
                className="action-icon edit-button"
                title="Edit"
                style={{
                  opacity: 1,
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon icon={faPenToSquare} />
              </button>
              <button
                type="button"
                className="action-icon trash-button"
                title="Delete"
                onClick={() => openDeleteModal(id)}
              >
                <FontAwesomeIcon icon={faTrashCan} />
              </button>
            </div>
          );
        },
      },
      {
        Header: "Add Value",
        accessor: "updated_at",
        Cell: ({ row }) => {
          const id = row.original.id;
          const name = row.original.name;

          return (
            <span>
              <button
                className="action-icon edit-button"
                title="Add Values"
                onClick={() => handleClick(id, name)}
              >
                Add Values
              </button>
            </span>
          );
        },
      },
    ],
    [currentPage] // Add deleteItemId as a dependency
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    state,
  } = useTable(
    {
      columns,
      data: varientlist, // Rename 'brandlist' to 'data'
      initialState: { pageIndex: 0, pageSize: 10, globalFilter: "" },
      state: (state) => ({
        ...state,
        noMatchFound: rows.length === 0 && state.globalFilter !== "",
      }),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <div className="page-data">
      <div className="page-heading">
        <div className="breadcrumb">
          <li className="active">Variants </li>
        </div>
      </div>

      <div className="page-container">
        <div className="table-serach-area">
          <button
            onClick={() => {
              handelAddVariant();
            }}
            className="btn primary-btn"
          >
            Add Variant
          </button>
          <div className="search-bar">
            <div className="fontAwsomeDiv">
              {searchLoading ? (
                <LoaderButton />
              ) : (
                <FontAwesomeIcon className="SearchIconDiv" icon={faSearch} />
              )}
            </div>
            <input
              id="seller-searchForProducts"
              placeholder="Search..."
              className="search-input-order"
              name="search-query"
              value={searchQuery}
              onChange={(e) => {
                e.preventDefault();
                const val = e.target.value;
                setSearchQuery(val);
                setIsSearchApplied(true);
              }}
            />
          </div>
        </div>
        <div className="AdminBrandSetupListDiv">
          <table {...getTableProps()}>
            <thead className="hadingelementsbrandSetups">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th>
                      {" "}
                      <div className="header-cell">
                        <span>{column.render("Header")}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {loading ? (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">Loading...</div>
                  </td>
                </tr>
              ) : page.length > 0 ? (
                // Check if there is data in the current page
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="TableBodyData" {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">No Variants Available.</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {/* Add Variant calls */}
          {addVariant && (
            <>
              <div
                className="modailmaindiv"
                style={{ border: "1px solid green" }}
              >
                <div className="AdminEditBrandBox">
                  <h3 className="category-modal-heading">Add Variant</h3>

                  <span
                    className="modal-close"
                    onClick={() => {
                      closeAddVariant();
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </span>

                  <label htmlFor="">Name</label>
                  <input
                    type="text"
                    name="name"
                    value={variant.name}
                    maxLength={30}
                    className="Add_category_input"
                    onChange={handleChange}
                    placeholder="Enter name"
                  />
                  {<div className="red">{vNameError}</div>}
                  <div className="modal-buttons">
                    <button
                      type="button"
                      className="btn secondary-btn"
                      onClick={() => {
                        closeAddVariant();
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn primary-btn"
                      onClick={() => {
                        setLoadingButton(true);
                        AddVarientData();
                      }}
                    >
                      {loadingButton ? <LoaderButton /> : "Add"}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* Pagination */}
          <div className="pagination-container">
            {pagination && (
              <Paginate
                pagination={pagination}
                pageChange={handleURLChange}
                pageName={"Variants"}
              />
            )}
          </div>
          {/* Variant delete Modal */}
          {showDeleteModal && (
            <div
              id="deleteModal"
              className="modal fade"
              style={{ display: showDeleteModal ? "block" : "none" }}
            >
              <div className="modal-content">
                <span className="modal-close" onClick={closeDeleteModal}>
                  <FontAwesomeIcon icon={faTimes} />
                </span>
                <h3 className="modal-header">Delete Confirmation</h3>
                <p className="modal-line">
                  Are you sure you want to delete Variant?
                </p>
                <div className="modal-buttons">
                  {deleteBtnLoading ? (
                    <button type="button" className="btn disabled-primary-btn">
                      Yes, Delete It!
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn primary-btn"
                      onClick={() => handleConfirmDelete(deleteId)}
                    >
                      Yes, Delete It!
                    </button>
                  )}
                  <button
                    className="btn secondary-btn"
                    onClick={closeDeleteModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Edit Variant Calls */}
          {editVariant && (
            <>
              <div
                className="modailmaindiv"
                style={{ border: "1px solid green" }}
              >
                <div className="AdminEditBrandBox">
                  <h3 className="category-modal-heading">Edit Variant</h3>

                  <span
                    className="modal-close"
                    onClick={() => {
                      closeEditVariant();
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </span>

                  <label htmlFor="">Name</label>
                  <input
                    type="text"
                    name="name"
                    maxLength={30}
                    value={variantEdit.name}
                    className="Add_category_input"
                    onChange={handleEditChange}
                    placeholder="Enter name"
                  />
                  <div className="red" style={{ maxWidth: "100%" }}>
                    {EditNameError}
                  </div>
                  <div className="modal-buttons">
                    <button
                      type="button"
                      className="btn secondary-btn"
                      onClick={() => {
                        closeEditVariant();
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn primary-btn"
                      onClick={() => {
                        setLoadingButton(true);
                        EditVarientData();
                      }}
                    >
                      {loadingButton ? <LoaderButton /> : "Edit"}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default VariantList;
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import LoaderButton from "../../../Components/LoaderButton";
// import Paginate from "../../../Components/Pagination/Paginate";
// import {
//   useTable,
//   useSortBy,
//   useGlobalFilter,
//   usePagination,
// } from "react-table";
// import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";
// import { useContext, useEffect, useMemo, useState } from "react";
// import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
// import {
//   getDataFromCookies,
//   removeDataFromCookie,
// } from "../../../Components/Cookie";
// import { useLocation, useNavigate } from "react-router-dom";
// import InputContext from "../../../Components/InputContext";
// import APIEndpoint from "../../../config/APIEndpoints.json";
// import stringConstant from "../../../config/ProjectConstants.json";
// import Navigation from "../../../config/Navigation.json";
// import Toast from "../../../Components/Toast";

// const API_HOST = process.env.REACT_APP_HOST_API_URL;

// const VariantList = () => {
//   const location = useLocation();
//   const [showDeleteModal, setShowDeleteModal] = useState(false);
//   const [loadingButton, setLoadingButton] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [lastPage, setLastPage] = useState(1);
//   const [pagination, setPagination] = useState({});
//   const [varientlist, setVarientlist] = useState([]);
//   const [searchLoading, setSearchLoading] = useState(false);
//   const variantPerPage = 10;
//   const [loading, setLoading] = useState(true);
//   const [searchQuery, setSearchQuery] = useState("");
//   const navigate = useNavigate();
//   const contextObj = useContext(InputContext);
//   const [totalCount, setTotalCount] = useState(0);
//   const [addVariant, showAddVariant] = useState(false);
//   const [selectedVariant, setSelectedVariant] = useState(null);
//   const [variant, setVariant] = useState({ name: "" });
//   const [variantEdit, setEditVariant] = useState({ name: "" });
//   const [editId, setEditId] = useState(null);
//   const [deleteBtnLoading, setDeleteBtnLoading] = useState(false);
//   const [deleteId, setDeleteId] = useState("");
//   const token = getDataFromCookies("7b9KpQ3rW6");
//   const [vNameError, setVNameError] = useState("");
//   const [editVariantOpen, showEditVariant] = useState(false);
//   const [EditNameError, setEditNameError] = useState("");

//   const { showToast } = Toast();

//   const CheckGetVarientList = async (url) => {
//     try {
//       if (!token) {
//         contextObj.setInput("prevPath", location.pathname);
//         navigate(`${Navigation.login}`);
//         return;
//       }
//       let fetchUrl = url;
//       if (searchQuery) {
//         fetchUrl += `&search=${searchQuery}`;
//       }
//       const response = await fetch(`${fetchUrl}&per_page=${variantPerPage}`, {
//         method: "GET",
//         headers: {
//           Accept: "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       const res = await response.json();
//       if (res.status === 401 && res.message === "You are not authorized.") {
//         contextObj.setInput("prevPath", location.pathname);
//         removeDataFromCookie("7b9KpQ3rW6", "/");
//         removeDataFromCookie("loggedIn", "/");
//         contextObj.setInput("navigation", "true");
//         contextObj.setInput("navigationFrom", "sessionExpired");
//         navigate(Navigation.login);
//         showToast("info", "Sessions expired!");
//       } else if (res.status === 401 && res.message !== "") {
//         showToast("error", res.message);
//       } else if (res.status === 404) {
//         setVarientlist([]);
//         setPagination({});
//       } else if (res.status === 200) {
//         setVarientlist(res.data.data);
//         setPagination(res.data);
//         setCurrentPage(res.data.current_page);
//         setLastPage(res.data.last_page);
//         setTotalCount(res.data.total);
//       }
//       setSearchLoading(false);
//       setLoading(false);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     CheckGetVarientList(`${API_HOST}/${APIEndpoint.CrudVariant}?page_no=1`);
//   }, []);

//   const handleClick = (id, name) => {
//     setSelectedVariant({ id, name });
//     navigate(`/variant-value/${id}`);
//   };

//   const handelAddVariant = () => showAddVariant(true);

//   const closeAddVariant = () => {
//     showAddVariant(false);
//     setVNameError("");
//     setVariant({ name: "" });
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setVNameError("");
//     setVariant((prevData) => ({
//       ...prevData,
//       [name]: value.trimStart(),
//     }));
//   };

//   const AddVarientData = async () => {
//     if (!variant.name) {
//       setVNameError("This field is required");
//       setLoadingButton(false);
//       return;
//     }

//     try {
//       const response = await fetch(`${API_HOST}/${APIEndpoint.addVarient}`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify(variant),
//       });
//       const data = await response.json();
//       setLoadingButton(false);
//       if (data.status === 201) {
//         showToast("success", stringConstant.alertErrorMessage.alertForVariantCreated);
//         closeAddVariant();
//         CheckGetVarientList(`${API_HOST}/${APIEndpoint.CrudVariant}?page_no=1`);
//       } else if (data.status === 400) {
//         if (data.errors.name) {
//           setVNameError(data.errors.name[0]);
//         }
//       } else if (data.status === 401 && data.message === "You are not authorized.") {
//         contextObj.setInput("prevPath", location.pathname);
//         removeDataFromCookie("7b9KpQ3rW6", "/");
//         removeDataFromCookie("loggedIn", "/");
//         contextObj.setInput("navigation", "true");
//         contextObj.setInput("navigationFrom", "sessionExpired");
//         navigate(Navigation.login);
//         showToast("info", "Sessions expired!");
//       } else if (data.status === 401 && data.message !== "") {
//         showToast("error", data.message);
//       }
//     } catch (err) {
//       console.error(err);
//     }
//   };

//   const openDeleteModal = (id) => {
//     setDeleteId(id);
//     setShowDeleteModal(true);
//   };

//   const closeDeleteModal = () => {
//     setDeleteId(null);
//     setShowDeleteModal(false);
//   };

//   const handleConfirmDelete = async (id) => {
//     try {
//       if (!token) {
//         contextObj.setInput("prevPath", location.pathname);
//         navigate(`${Navigation.login}`);
//         return;
//       }

//       setDeleteBtnLoading(true);
//       const response = await fetch(`${API_HOST}/${APIEndpoint.CrudVariant}/${id}`, {
//         method: "DELETE",
//         headers: {
//           Accept: "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       if (response.status === 200) {
//         showToast("success", "Variant deleted successfully");
//         if (currentPage === lastPage && totalCount % variantPerPage === 1) {
//           setCurrentPage(currentPage - 1);
//         }
//         CheckGetVarientList(`${API_HOST}/${APIEndpoint.CrudVariant}?page_no=${currentPage}`);
//       } else if (response.status === 401) {
//         navigate(Navigation.login);
//         removeDataFromCookie("7b9KpQ3rW6", "/");
//         removeDataFromCookie("loggedIn", "/");
//         showToast("info", "Session expired!");
//       } else if (response.status === 401 && response.message !== "") {
//         showToast("error", response.message);
//       } else {
//         showToast("info", response.message || "Something went wrong");
//       }
//     } catch (error) {
//       console.error("Error deleting Variant:", error);
//       showToast("error", "An error occurred while deleting Variant.");
//     } finally {
//       setDeleteBtnLoading(false);
//       closeDeleteModal();
//     }
//   };

//   const varientEdit = (id, name) => {
//     showEditVariant(true);
//     setEditId(id);
//     setEditVariant({ name });
//   };

//   const closeEditVariant = () => {
//     showEditVariant(false);
//     setEditNameError("");
//     setEditVariant({ name: "" });
//   };

//   const handleEditChange = (e) => {
//     const { name, value } = e.target;
//     setEditNameError("");
//     setEditVariant((prevData) => ({
//       ...prevData,
//       [name]: value.trimStart(),
//     }));
//   };

//   const EditVarientData = async () => {
//     if (!variantEdit.name) {
//       setEditNameError("This field is required");
//       return;
//     }

//     try {
//       const response = await fetch(`${API_HOST}/${APIEndpoint.CrudVariant}/${editId}`, {
//         method: "PUT",
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify(variantEdit),
//       });
//       const data = await response.json();
//       if (data.status === 200) {
//         showToast("success", "Variant updated successfully");
//         closeEditVariant();
//         CheckGetVarientList(`${API_HOST}/${APIEndpoint.CrudVariant}?page_no=${currentPage}`);
//       } else if (data.status === 400) {
//         if (data.errors.name) {
//           setEditNameError(data.errors.name[0]);
//         }
//       } else if (data.status === 401 && data.message === "You are not authorized.") {
//         contextObj.setInput("prevPath", location.pathname);
//         removeDataFromCookie("7b9KpQ3rW6", "/");
//         removeDataFromCookie("loggedIn", "/");
//         contextObj.setInput("navigation", "true");
//         contextObj.setInput("navigationFrom", "sessionExpired");
//         navigate(Navigation.login);
//         showToast("info", "Sessions expired!");
//       } else if (data.status === 401 && data.message !== "") {
//         showToast("error", data.message);
//       }
//     } catch (err) {
//       console.error(err);
//     }
//   };

//   // Define columns for React Table
//   const columns = useMemo(
//     () => [
//       {
//         Header: "Name",
//         accessor: "name",
//       },
//       {
//         Header: "Actions",
//         Cell: ({ row }) => (
//           <div>
//             <button onClick={() => varientEdit(row.original.id, row.original.name)}>
//               <FontAwesomeIcon icon={faPenToSquare} />
//             </button>
//             <button onClick={() => openDeleteModal(row.original.id)}>
//               <FontAwesomeIcon icon={faTrashCan} />
//             </button>
//           </div>
//         ),
//       },
//     ],
//     []
//   );

//   const tableInstance = useTable(
//     {
//       columns,
//       data: varientlist,
//       initialState: { pageIndex: currentPage - 1 },
//     },
//     useGlobalFilter,
//     useSortBy,
//     usePagination
//   );

//   const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = tableInstance;

//   return (
//     <div>
//       <div>
//         <button onClick={handelAddVariant}>Add Variant</button>
//         <input
//           type="text"
//           placeholder="Search"
//           value={searchQuery}
//           onChange={(e) => setSearchQuery(e.target.value)}
//         />
//         <button onClick={() => setSearchLoading(true)}>Search</button>
//         {searchLoading && <span>Loading...</span>}
//       </div>

//       {loading ? (
//         <div>Loading...</div>
//       ) : (
//         <table {...getTableProps()}>
//           <thead>
//             {headerGroups.map(headerGroup => (
//               <tr {...headerGroup.getHeaderGroupProps()}>
//                 {headerGroup.headers.map(column => (
//                   <th {...column.getHeaderProps(column.getSortByToggleProps())}>
//                     {column.render('Header')}
//                     {column.canSort && (
//                       <span>
//                         {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
//                       </span>
//                     )}
//                   </th>
//                 ))}
//               </tr>
//             ))}
//           </thead>
//           <tbody {...getTableBodyProps()}>
//             {page.map(row => {
//               prepareRow(row);
//               return (
//                 <tr {...row.getRowProps()}>
//                   {row.cells.map(cell => (
//                     <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
//                   ))}
//                 </tr>
//               );
//             })}
//           </tbody>
//         </table>
//       )}

//       <Paginate
//         currentPage={currentPage}
//         totalPages={lastPage}
//         onPageChange={(page) => {
//           setCurrentPage(page);
//           CheckGetVarientList(`${API_HOST}/${APIEndpoint.CrudVariant}?page_no=${page}`);
//         }}
//       />

//       {/* Add Variant Modal */}
//       {addVariant && (
//         <div>
//           <h2>Add Variant</h2>
//           <input
//             type="text"
//             name="name"
//             value={variant.name}
//             onChange={handleChange}
//             placeholder="Variant Name"
//           />
//           {vNameError && <span>{vNameError}</span>}
//           <button onClick={AddVarientData} disabled={loadingButton}>
//             {loadingButton ? "Adding..." : "Add"}
//           </button>
//           <button onClick={closeAddVariant}>Cancel</button>
//         </div>
//       )}

//       {/* Edit Variant Modal */}
//       {editVariantOpen && (
//         <div>
//           <h2>Edit Variant</h2>
//           <input
//             type="text"
//             name="name"
//             value={variantEdit.name}
//             onChange={handleEditChange}
//             placeholder="Variant Name"
//           />
//           {EditNameError && <span>{EditNameError}</span>}
//           <button onClick={EditVarientData}>Update</button>
//           <button onClick={closeEditVariant}>Cancel</button>
//         </div>
//       )}

//       {/* Delete Confirmation Modal */}
//       {showDeleteModal && (
//         <div>
//           <h2>Confirm Delete</h2>
//           <button onClick={() => handleConfirmDelete(deleteId)} disabled={deleteBtnLoading}>
//             {deleteBtnLoading ? "Deleting..." : "Confirm"}
//           </button>
//           <button onClick={closeDeleteModal}>Cancel</button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default VariantList;
