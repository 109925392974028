import React, { useState, useContext, useEffect, useRef } from "react";
import LazyImage from "./LazyImage";
import "./card.css";
import Toast from "../../../Components/Toast";
const API_HOST = process.env.REACT_APP_HOST_API_URL;
let currentInfra = process.env.REACT_APP_INFRA.toLowerCase();
const S3BucketImgHost = process.env.REACT_APP_S3BUCKET_IMG_HOST;
const Card = (props) => {
  const { showToast } = Toast();
  let imgHost =
    currentInfra === "staging" || currentInfra === "production"
      ? S3BucketImgHost
      : API_HOST;

  const [item, setItem] = useState(props.item);
  const getOfferPercentage = (mrp, sellingPrice) => {
    if (mrp !== null && sellingPrice !== null) {
      const percentage = Math.round(((mrp - sellingPrice) / mrp) * 100);

      if (mrp <= 0 || sellingPrice <= 0 || percentage <= 0) return 0;
      return percentage;
    }
  };
  useEffect(() => {
    setItem(props.item);
  }, [props.item]);
  const formatNumberWithCommas = (number) => {
    if (number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return number;
  };

  const getBackgroundColor = (condition) => {
    if (condition) {
      switch (condition.toUpperCase()) {
        case "NEW":
          return "#379D75";
        case "ALMOST LIKE NEW":
          return "#F7BC3A";
        case "GENTLY LOVED":
          return "#9AC2E6";
        case "LOVED":
          return "#F4ABA4";
        default:
          return "";
      }
    }
  };

  const getBorderColor = (condition) => {
    switch (condition.toUpperCase()) {
      case "NEW":
        return "rgba(55, 157, 117, 0.5) transparent rgba(55, 157, 117, 0.5) rgba(55, 157, 117, 1)";
      case "ALMOST LIKE NEW":
        return "rgba(247, 188, 58, 0.5) transparent rgba(247, 188, 58, 0.5) rgba(247, 188, 58, 1)";
      case "GENTLY LOVED":
        return "rgba(154, 194, 230, 0.5) transparent rgba(154, 194, 230, 0.5) rgba(154, 194, 230, 1)";
      case "LOVED":
        return "rgba(244, 171, 164, 0.5) transparent rgba(244, 171, 164, 0.5) rgba(244, 171, 164, 1)";
      default:
        return "";
    }
  };

  const [showRemove, setShowRemove] = useState(false);
  return (
    <div
      className="prod-card"
      onClick={() => {
        if (props.removeFromList) {
          let updatedCollection = props.productsList.filter((product) => {
            return product.id !== item.id;
          });
          props.productsCollection((prev) => ({
            ...prev,
            products: updatedCollection,
          }));
          let updatedSelectedProducts = props.productsList.filter((product) => {
            return product.id !== item.id;
          });
          props.productsCollection((prev) => ({
            ...prev,
            products: updatedCollection,
          }));


          let selectedProducts = props.selectedProducts.filter((product) => {
            return product.id !== item.id;
          });

          props.setSelectedProducts([...selectedProducts]);
        } else {
          if ("isSelected" in item) {
            let currentState = props.productsList[props.cardIndex].isSelected;
            props.productsList[props.cardIndex].isSelected = !currentState;

            let selectedProducts = props.selectedProducts;
           if (currentState) {
              // filterFromSelectedArray
              selectedProducts = selectedProducts.filter(
                (product) => product.id !== item.id
              );
            } else {
              if (
                props.initialProducts.length >= 20 ||
                props.initialProducts.length +
                  props.selectedProducts.length >=
                  20
              ) {
                return showToast(
                  "info",
                  "Maximum 20 products are allowed in the collection."
                );
              }
              // addToSelectedArray
              else {
                item.isSelected = true;
                selectedProducts.push(item);
              }
            }
            if (props.setProductsList) {
              props.setProductsList([...props.productsList]);
            }

            props.setSelectedProducts([...selectedProducts]);
          }
        }
      }}
      onMouseEnter={(e) => {
        if (props.removeFromList) {
          setShowRemove(true);
        } else {
          setShowRemove(false);
        }
      }}
      onMouseLeave={(e) => {
        setShowRemove(false);
      }}
    >
      {!showRemove && item.isSelected && (
        <div className="selectedCard">Selected</div>
      )}
      {showRemove && <div className="removeCard">Remove</div>}
      <div className="prod-image">
        <LazyImage
          className="prod-thumbnail"
          src={`${imgHost}/${item.thumb_image}`}
          alt="image"
          loadingImg={props.loadingImg}
        />
        <label
          className="prod-condition-ribbon"
          style={{
            backgroundColor: getBackgroundColor(item.condition),
            "--border-color": getBorderColor(item.condition),
          }}
        >
          {item.condition.toUpperCase()}
        </label>
      </div>
      <div className="prod-desc">
        <p className="prod-selling-price">
          <span className="productCardRupeeSymbol">₹</span>{" "}
          {formatNumberWithCommas(item.selling_price)}{" "}
          {getOfferPercentage(item.current_market_price, item.selling_price) >
            0 && (
            <span className="offer-discount">
              {getOfferPercentage(
                item.current_market_price,
                item.selling_price
              )}
              % off
            </span>
          )}
        </p>
        <p className="prod-estimated-price">
          (Est. Original Price:{" "}
          <span>₹ {formatNumberWithCommas(item.current_market_price)}</span>)
        </p>
        <p className="prod-title">{item.name}</p>
      </div>
      {item.selected && <></>}
    </div>
  );
};

export default Card;
